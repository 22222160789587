
import { useState, useEffect, useRef, useCallback } from 'react';
import useLocalStorageState from 'use-local-storage-state';

import { ReactSVG } from 'react-svg';

import css from '../App.module.css';


function NetworkTab(props) {
	const [svgURL, setSvgURL] = useState('')	
	const svgRef = useRef();
	const svgInfo = useRef();
	const [svgOptions, setSvgOptions] = useLocalStorageState(props.page + '_' + props.objectClass + '_svgoptions', { defaultValue: {'orientation':false, 'expand': false, zoom:1000, select_clusters:[], cluster_node:''} })
	const orientationRef = useRef();
	const [clusters, setClusters] = useState([]);


	const checkSvgOption = (event) => {		
		svgOptions[event.currentTarget.id] = event.currentTarget.checked;
		setSvgOptions(svgOptions);		
		return true;
	};

	// handle readout selection dropdown
	const clusterHandler = (event) => {	
		const clusterID = event.currentTarget.id;

		if ('all' === clusterID) {
			svgOptions.select_clusters = [];
		} else {
			const index = svgOptions.select_clusters.indexOf(clusterID);

			if (index === -1) {
				svgOptions.select_clusters.push(clusterID);
			} else {
				svgOptions.select_clusters.splice(index, 1);
			}
		}
		setSvgOptions(svgOptions);
	}

	// handle expand selection dropdown
	const expandHandler = (event) => {	
		svgOptions.expand = event.currentTarget.id;		
		setSvgOptions(svgOptions);
	}

	const dblClickSVG = useCallback((event) => {
		let rootg = document.getElementById('graph0');
		let svg = rootg.parentElement;
		var bBox = svg.getBBox();

		if (!(svgOptions.zoom) || 0 === svgOptions.zoom) {
			svgOptions.zoom = 100;
			svgInfo.current = {h:bBox.height, w:bBox.width}
			svg.setAttribute("height", "100%");		
			svg.setAttribute("width", "100%");
		} else {
			svgOptions.zoom = 0;
			svg.setAttribute("height", svgInfo.current.h);		
			svg.setAttribute("width", svgInfo.current.w);
		}
		setSvgOptions(svgOptions);
		return false;
	}, [svgOptions, setSvgOptions]);


	// select node in svg
	const clickNode = useCallback((event) => {
		//alert('click ' + event.currentTarget.id);
		svgOptions.cluster_node = event.currentTarget.id !== svgOptions.cluster_node ? event.currentTarget.id : '';
		setSvgOptions(svgOptions);
	}, [svgOptions, setSvgOptions]);
	

	const afterInjection = useCallback(() => {	
		// set up box info
		let rootg = document.getElementById('graph0');
		let svg = rootg.parentElement;
		var bBox = svg.getBBox();
		let newInfo = {h:bBox.height, w:bBox.width}

		if (!svgInfo.current || 100 === svgOptions.zoom) {
			svg.setAttribute("height", "100%");		
			svg.setAttribute("width", "100%");
		}
		svgInfo.current = newInfo;

		// set up click handlers	
		let stateNodeElements = [...document.getElementsByClassName("node")];

		stateNodeElements.forEach(elem => {
			elem.addEventListener("click", clickNode);
		});

	}, [clickNode, svgOptions.zoom]);


	useEffect(()=> {
		setSvgURL("")
		let clusters = [];

		// load clusters from current object if there
		if ('network' in props.contentObject) {
			const	network = props.contentObject.network;

			if ('clusters' in network) {
				clusters = network.clusters;
			}
		}
		setClusters(clusters);
		
		if (!svgOptions.hasOwnProperty('select_clusters')) {
			svgOptions.select_clusters = [];
		}
		// remove any selected clusters no longer present
		svgOptions.select_clusters.filter(item => !clusters.includes(item));
		setSvgOptions(svgOptions)

 		if (props.apiCxt.apiToken && props.contentObject && 'network' in props.contentObject && 'network' === props.activeTab ) {
			var url = props.apiCxt.endpoint + props.contentObject['PK'].replace("$", "/") + "/network.svg?&api-token="+props.apiCxt.apiToken
			
			if (svgOptions.orientation) {
				url += '&orientation=vertical'
			}
			if (svgOptions.expand) {
				url += '&expand=' + svgOptions.expand;
			}
			if (svgOptions.readouts) {
				url += '&readouts=none'
			}
			if (svgOptions.cluster_node) {
				url += '&cluster_node=' + svgOptions.cluster_node;
			}
			if (0 < svgOptions.select_clusters.length) {
				url += '&clusters=' + svgOptions.select_clusters.join();
			}
			setSvgURL(url)
		}
	}, [props.contentObject, props.apiCxt, props.activeTab, svgOptions, setSvgOptions])
		

	return(
		<div id={props.page + '_content'} >
			<div style={{'position':'absolute','top':'8px','bottom':'26px','left':'8px','right':'8px', 'overflow':'auto'}} >
				<ReactSVG id={props.page + '_svg'} src={svgURL} ref={svgRef} afterInjection={afterInjection} onDoubleClickCapture={dblClickSVG} /> 
			</div>
			<div style={{'position':'absolute', 'bottom':'0px'}}>
				<button className="dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
					Clusters: {'select_clusters' in svgOptions ? (0 === svgOptions.select_clusters.length ? clusters.length : svgOptions.select_clusters.length) : 0}
				</button >
				<ul className="dropdown-menu" style={{'height':'300px','overflowX':'hidden','overflowY':'auto'}}>
					<li key="all" ><button id='all' className={'dropdown-item ' + (0 === svgOptions.select_clusters.length ? css.dropdownItemChecked : '')} href="#"  onClick={clusterHandler} >all</button ></li>
					<li key="none" ><hr className="dropdown-divider"/></li>
					{ clusters.map((cluster) => {
							return <li key={cluster.label} ><button id={cluster.label} className={'dropdown-item ' + (-1 !== svgOptions.select_clusters.indexOf(cluster.label) ? css.dropdownItemChecked : '')} href="#"  onClick={clusterHandler} >{cluster.label}</button ></li>
						})
					}
				</ul>
				&nbsp;&nbsp;&nbsp;
				<button className="dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
					Expand: {svgOptions.expand}
				</button >
				<ul className="dropdown-menu" >
					<li key="all" ><button id='all' className={'dropdown-item ' + ('all' === svgOptions.expand ? css.dropdownItemChecked : '')} href="#"  onClick={expandHandler} >all</button ></li>
					<li key="edge_nodes" ><button id='edges' className={'dropdown-item ' + ('edges' === svgOptions.expand ? css.dropdownItemChecked : '')} href="#"  onClick={expandHandler} >edges</button ></li>
					<li key="none" ><button id='none' className={'dropdown-item ' + ('none' === svgOptions.expand ? css.dropdownItemChecked : '')} href="#"  onClick={expandHandler} >none</button ></li>
				</ul>
				&nbsp;&nbsp;&nbsp;
				<input id="orientation" type="checkbox" checked={svgOptions.orientation} onChange={checkSvgOption} ref={orientationRef} />
				<label htmlFor="orientation">&nbsp;Vertical Orientation</label>
			</div>
		</div>
	);
}

export default NetworkTab;


import { useState, useEffect, useCallback } from 'react';
import useLocalStorageState from 'use-local-storage-state'

import { TreeView, TreeItem } from '@mui/x-tree-view';
import '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

import V3ListHeader from "./V3ListHeader";
import V3Object from './V3Object';
import css from './V3Object.module.css';

function V3ObjectList(props) {
	const [isLoading, setIsLoading] = useState(true);
	const [objects, setObjects] = useState([]);
	const [myCxt, setMyCxt] = useState({account:'', platform:'', parentPK:'', listHeader:''});	//display context
	const [listPK, setListPK] = useLocalStorageState(props.page + '_' + props.objectClass + '_listPK', { defaultValue: "" })
	const [parentPK, setParentPK] = useLocalStorageState(props.page + '_' + props.parentClass + '_listPK', { defaultValue: "" })
	const [contentPK] = useLocalStorageState(props.page + '_contentPK', { defaultValue: "" });
	const [listHeader, setListHeader] = useLocalStorageState(props.page + '_' + props.objectClass + '_listHeader', { defaultValue: {'IDQuery':'', 'tagQuery':'', 'groupTags':false, 'rootModel': false, 'tagGroups':{},'untagged':[]} })

	
	const refreshList = useCallback((ppk=null) => {
		setObjects([]);
		//setListPK('')
		
		if (props.apiCxt.apiToken) {
			try {
				var url = props.apiCxt.endpoint + props.objectClass + '?order-desc=date&notify=none&limit=5000&api-token=' + props.apiCxt.apiToken

				if (props.parentClass) {
					let PPK = ppk ? ppk : parentPK;

					if (!PPK) {
						if ('platforms' === props.parentClass && props.apiCxt.platform) {
							PPK = "platforms$" + props.apiCxt.account + ":" + props.apiCxt.platform;
						} else {
							setIsLoading(false);
							setListPK('')
							return;
						}
					}

					if ("subjects" === PPK.split('$')[0]) {
						url += '&subject_key=' + PPK
					} else {
						url += '&parent_key=' + PPK
					}
				}
				if (listHeader.IDQuery) {
					url += '&' + props.class_id + '=' + listHeader.IDQuery
				}
				if (listHeader.tagQuery) {
					url += '&tags=' + listHeader.tagQuery
				}
				if (props.apiCxt.account) {
					url += '&account=' + props.apiCxt.account
				}  

				setIsLoading(true);
				fetch(
					url
				).then(response => {
					return response.json();
				}).then(data => {
					if (props.objectClass in data) {
						setIsLoading(false)

						let rtnObjects = data[props.objectClass];

						setObjects(rtnObjects);	

						var	groups = {};
						var	untagged = [];
						var clearListPK = true;

						rtnObjects.forEach((rtnObject) => {
							if (listPK === rtnObject['PK']) {
								clearListPK = false;
							}

							if ('tags' in rtnObject) {
								let rtnTags = rtnObject['tags'];
								
								Object.keys(rtnTags).forEach((tagName) => {

									if (!(tagName in groups)) {
										groups[tagName] = {}
									}

									var tg = groups[tagName]
									let tagValue = rtnTags[tagName]

									if (tagValue) {
										if (!(tagValue in tg)) {
											tg[tagValue] = []
										}
										tg[tagValue].push(rtnObject)
									} else {
										if (!('untagged' in tg)) {
											tg['untagged'] = []
										}
										tg['untagged'].push(rtnObject)
									}
								})
							} else {
								untagged.push(rtnObject)
							}
						})

						if (clearListPK) {
							setListPK('')
						}
						//setTagGroups({'groups':groups,'untagged':untagged})
						listHeader.tagGroups = groups;
						listHeader.untagged = untagged;
						setListHeader(listHeader);
 					} else if ('exception' in data) {
						let msg = 'exception: ' + data['exception']
						
						console.log(msg);
						//document.getElementById(props.page + '_' + props.parentClass + '_loading').textContent = msg;
						alert(msg)
						setListPK('')
						setIsLoading(false)
					}
				});	
			} catch (error) {
				setObjects([]);
				console.log('There was an error', error);
				document.getElementById(props.page + '_' + props.parentClass + '_loading').textContent = error;
			};
		} else {
			setListPK('')
		}
	}, [listHeader, setListHeader, parentPK, listPK, setListPK, props]);

	useEffect(()=> {
		// only refresh list if our display context has changed
		let newCxt = {account:props.apiCxt.account, platform:props.apiCxt.platform, parentPK:parentPK, listHeader:listHeader}

		if (JSON.stringify(newCxt) !== JSON.stringify(myCxt)) { 
			setMyCxt(newCxt);

			refreshList();
			console.log(props.objectClass + ' myCxt refresh')
		} else {
			//special case to allow posteriors to be listed under both subjects and records
			if ('posteriors' === props.objectClass) {
				let selectedClass = contentPK.split('$')[0];

				if ('subjects' === selectedClass) {
					console.log(props.objectClass + ' parentClass refresh')
					refreshList(contentPK);
				}
			}
		}
	}, [props, myCxt, listHeader, setListHeader, parentPK, setParentPK, contentPK, refreshList])

	
	const handleCancelIDFilter = () => {
		listHeader.IDQuery = '';
		setListHeader(listHeader);
	};

	return(
		<div style={{'position':'absolute','width':'100%','height':'100%'}} >
			<V3ListHeader page={props.page} objectClass={props.objectClass} parentClass={props.parentClass} apiCxt={props.apiCxt} setListPK={setListPK} setContentPK={props.setContentPK} refreshList={refreshList} className='resourceHeader' />
			{listHeader.IDQuery && <div className={css.filterText} style={{'width':'100%'}}><span>{listHeader.IDQuery}</span><CancelPresentationIcon className={css.filterCancel} style={{'fontSize':'small'}} onClick={handleCancelIDFilter}/></div>}
			{isLoading && 
				<section><p id={props.page + '_' + props.parentClass + '_loading'}>Loading...</p></section>
			}
			{!isLoading && 
				<div>
					{ !listHeader.groupTags && 
						<ul className={css.resourceList} style={{'top':(listHeader.IDQuery ? '52px':'28px')}}>
							{ 'models' === props.objectClass && listHeader.rootModel &&
								<V3Object 
									key={"grids$" + props.apiCxt.account + ":"} 
									object={{"PK":"grids$" + props.apiCxt.account + ":","model_id":"grids$" + props.apiCxt.account + ":","name":"All Grids"}} 
									class_id={props.class_id} 
									listPK={listPK} 
									setListPK={setListPK}
									contentPK={props.contentPK}
									setContentPK={props.setContentPK} 
									setRefreshCallback={props.setRefreshCallback}
									refreshList={refreshList}
									></V3Object>
							}
							{ objects.map((object) => {
									return <V3Object 
											key={object.PK} 
											object={object} 
											class_id={props.class_id} 
											idQuery={listHeader.IDQuery}
											listPK={listPK} 
											setListPK={setListPK}
											contentPK={props.contentPK}
											setContentPK={props.setContentPK} 
											setRefreshCallback={props.setRefreshCallback}
											refreshList={refreshList}
										></V3Object>
								})
							}
						</ul>

					}
					{ listHeader.groupTags && 
						<ul className={css.resourceList}>
							<TreeView 
								defaultCollapseIcon={<ExpandMoreIcon />}
								defaultExpandIcon={<ChevronRightIcon />}
								sx={{
									margin: 0,
									flexGrow: 1,
									width: '100%',
									position: 'absolute',
									top: 0,
									bottom: 0,
									overflow: 'auto',
								}}
							>{ 
								Object.keys(listHeader.tagGroups).map((tagName, i) => {
										return <TreeItem key={'tag_' + i} nodeId={tagName} label={tagName} >{
											Object.keys(listHeader.tagGroups[tagName]).map((tagValue, j) => {
												return <TreeItem key={'tag_value_' + i*j} nodeId={tagValue} label={tagValue} >{
													listHeader.tagGroups[tagName][tagValue].map((object) => {
														//return <TreeItem nodeId={object['PK']} label={object['PK']} ></TreeItem>
														return <V3Object 
															key={tagName + tagValue + object.PK} 
															object={object} 
															class_id={props.class_id} 
															listPK={listPK} 
															setListPK={setListPK}
															contentPK={props.contentPK}
															setContentPK={props.setContentPK} 
															setRefreshCallback={props.setRefreshCallback}
															refreshList={refreshList}
														></V3Object>
													})
												}</TreeItem>
											})
										}</TreeItem>
									})
								}
								{ listHeader.untagged.map((object) => {
										//return <TreeItem nodeId={object['PK']} label={object['PK']} ></TreeItem>
										return <V3Object 
											key={object.PK} 
											object={object} 
											class_id={props.class_id} 
											listPK={listPK} 
											setListPK={setListPK}
											contentPK={props.contentPK}
											setContentPK={props.setContentPK} 
											setRefreshCallback={props.setRefreshCallback}
											refreshList={refreshList}
										></V3Object>
									})
							}
							</TreeView>
						</ul>
					}
				</div>
			}
		</div>
	);
}

export default V3ObjectList;

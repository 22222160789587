
import { useState, useRef, useEffect, useCallback } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Slider from '@mui/material/Slider';

import PropertyEditor from '../components/PropertyEditor';
import RequestDialog from '../UI/RequestDialog';

import { ReactSVG } from 'react-svg';

import css from '../App.module.css'

function SimResultsTab(props) {
	const [resultsOpen, setResultsOpen] = useState(false);  
	const [isLoading, setIsLoading] = useState(false);
	const [gridStatus, setGridStatus] = useState([]);
	const [isSliceLoading, setIsSliceLoading] = useState(false);
	const [sliceStatus, setSliceStatus] = useState('');
	const [isBatchLoading, setIsBatchLoading] = useState(false);
	const [batchOpen, setBatchOpen] = useState(false);  
	const [batchStatus, setBatchStatus] = useState({});
	const [viIndex, setViIndex] = useState('1');
	const viIndexRef = useRef();
	const [scenIndex, setScenIndex] = useState('1');
	const scenIndexRef = useRef();	
	const dataReadouts = useRef();
	const [checkReadout, setCheckReadout] = useState('');
	const [isImageLoading, setIsImageLoading] = useState(false);
	const [sliderInfo, setSliderInfo] = useState({extents:{}, offsets:{}, selection:{}});	// maps for viDef sliders
	const [svgURL, setSvgURL] = useState('')	
	const svgRef = useRef();
	const [measurementsData, setMeasurementsData] = useState("");
	const [metricsData, setMetricsData] = useState({});
	const [reqDlgOpen, setReqDlgOpen] = useState(false);
	const [reqDlgProps, setReqDlgProps] = useState({method:'', url:'', data:null, object:{}});
	
/*
	useEffect(()=> {
		setGridStatus({});
		setCheckReadout('')
		setViIndex('1')
		setScenIndex('1')
		setHtmlData("");

 		if (props.apiCxt.apiToken && props.contentPK && 'grids' === props.contentPK.split('$')[0]) {
			setIsLoading(true);
			
			try {
				var url = props.apiCxt.endpoint + props.contentPK.replace("$", "/") + "/status?&api-token="+props.apiCxt.apiToken
				
				fetch(
					url
				).then(response => {
					return response.json();
				}).then(respData => {
					setIsLoading(false);
					setGridStatus(respData['status']);			
				});	
			} catch (error) {
				console.log('There was an error', error);
			};
		}
	}, [props.contentPK, props.objectClass, props.apiCxt])
*/

	useEffect(()=> {
		dataReadouts.current = []
		if (props.contentObject && 'scen_def' in props.contentObject && 'sim-results' === props.activeTab) {
			dataReadouts.current = props.contentObject['scen_def']['dataReadouts'];

			try {
				//get axis info for readout slider controls
				let url = props.apiCxt.endpoint + props.contentObject['PK'].replace("$", "/") + "/vi_def/extents?&api-token="+props.apiCxt.apiToken
				
				fetch(
					url
				).then(response => {
					return response.json();
				}).then(respData => {
					// set map {axisName->[axisValues]}
					sliderInfo.extents = respData['extents'];

					// calculate offsets in reverse axisName order
					let offset = 1
					Object.keys(respData['extents']).reverse().map((axisName) => {
						sliderInfo.offsets[axisName] = offset;
						offset *= respData['extents'][axisName].length;
						return null;
					});
					
					// set selection map to 0
					Object.keys(respData['extents']).map((axisName) => {
						sliderInfo.selection[axisName] = 0;
						return null;
					});
					setSliderInfo(sliderInfo);
				});	
			} catch (error) {
				console.log('There was an error', error);
				setIsImageLoading(false);
			};			
		}
	}, [props.contentObject, props.contentPK, props.apiCxt, props.activeTab, sliderInfo])


	const handleResultsArrow = (event) => {
		if (!resultsOpen) {
			resultsHandler();	//call batch status if we're about to open it
		}
		setResultsOpen(!resultsOpen)
	}

	// click on 'RESULTS' button to update status
	const resultsHandler = (event) => {
		loadStatus();
	}

	const handleDeleteResults = () => {
		reqDlgProps.method = 'DELETE';
		reqDlgProps.url = props.apiCxt.endpoint + 'grids/' + props.contentPK + '/results?notify=none&api-token=' + props.apiCxt.apiToken;
		setReqDlgOpen(true);
	};

	const loadStatus = useCallback(() => {		
		setIsLoading(true);
		setGridStatus({});
		setSliceStatus('');
		
		if (props.apiCxt.apiToken && props.contentPK && 'grids' === props.contentPK.split('$')[0] && 'sim-results'=== props.activeTab) {
			//document.getElementById('search-input').value = props.contentPK;
			try {
				var url = props.apiCxt.endpoint + props.contentPK.replace("$", "/") + "/status?&api-token="+props.apiCxt.apiToken

				fetch(
					url
				).then(response => {
					return response.json();
				}).then(respData => {
					setIsLoading(false);

					let status = {};
					
					if (respData.hasOwnProperty('results')) {
						status = respData['results'];
					} else if (respData.hasOwnProperty('status')) {
						status = respData['status'];
					}

					if (status) {
						status['completed'] = Math.floor((status['completed_sim_count'] / status['sim_count']) * 100);
						status['vi_count'] = status['vi_count'].toLocaleString();
						status['sim_count'] = status['sim_count'].toLocaleString();
						status['remaining_sim_count'] = status['remaining_sim_count'].toLocaleString();
						status['error_sim_count'] = status['error_sim_count'].toLocaleString();

						setGridStatus(status);		
					}
					if (respData.hasOwnProperty('slices')) {
						const total = respData.slices['totalSlices']
						const remaining = respData.slices['remaining']

						setSliceStatus(total-remaining + '/' + total);
						setIsSliceLoading(false);
					}
				});	
			} catch (error) {
				console.log('There was an error', error);
			};
		}
	}, [props.apiCxt.apiToken, props.apiCxt.endpoint, props.contentPK, props.activeTab]);


	// click on 'Slices' button to update status
	const sliceHandler = (event) => {
		updateSliceStatus();
	}

	const updateSliceStatus = useCallback(() => {		
		setIsSliceLoading(true)
		setSliceStatus('');
		
		if (props.apiCxt.apiToken && props.contentPK && 'sim-results'=== props.activeTab) {
			//document.getElementById('search-input').value = props.contentPK;
			try {
				var url = props.apiCxt.endpoint + props.contentPK.replace("$", "/") + "/slices/status?api-token="+props.apiCxt.apiToken

				fetch(
					url,
					{method: "POST"}
				).then(response => {
					return response.json();
				}).then(respData => {
					if (respData.hasOwnProperty('slices')) {
						const total = respData.slices['totalSlices']
						const remaining = respData.slices['remaining']
	
						setSliceStatus(total-remaining + '/' + total);
						setIsSliceLoading(false);
					}
				});	
			} catch (error) {
				console.log('There was an error', error);
				setIsSliceLoading(false);
			};
		}
	}, [props.apiCxt.apiToken, props.apiCxt.endpoint, props.contentPK, props.activeTab]);
	
	// click on 'BATCH' button to load batch job status

	const batchHandler = useCallback((event) => {
		setIsBatchLoading(true);

 		if (props.apiCxt.apiToken && props.contentPK && 'grids' === props.contentPK.split('$')[0] && 'sim-results' === props.activeTab) {
			//document.getElementById('search-input').value = props.contentPK;
			try {
				var url = props.apiCxt.endpoint + props.contentPK.replace("$", "/") + "/batch?&api-token="+props.apiCxt.apiToken
				
				fetch(
					url
				).then(response => {
					return response.json();
				}).then(respData => {
					setIsBatchLoading(false);					
					setBatchStatus(respData)		
				});	
			} catch (error) {
				alert(error)
				setIsBatchLoading(false);
			};
		}
	}, [props.apiCxt.apiToken, props.apiCxt.endpoint, props.contentPK, props.activeTab]);


	// reload status if object selection changes
	useEffect(()=> {
		loadStatus();
		batchHandler();
	}, [props.contentObject, props.contentPK, props.apiCxt, loadStatus, batchHandler])


	const handleBatchArrow = (event) => {
		if (!batchOpen) {
			batchHandler();	//call batch status if we're about to open it
		}
		setBatchOpen(!batchOpen)
	}

	const handleTerminateJobs = () => {
		reqDlgProps.method = 'DELETE';
		reqDlgProps.url = props.apiCxt.endpoint + 'grids/' + props.contentPK + '/batch?notify=none&api-token=' + props.apiCxt.apiToken
		reqDlgProps.object = props.contentObject;
		setReqDlgProps(reqDlgProps);
		setReqDlgOpen(true);
	};

	const viIndexHandler = (event) => {
		//if (!event.currentTarget.value || /^\d+$/.test(event.currentTarget.value)) {	//allow empty string or integers
		if (!event.currentTarget.value || /^[@,0-9]+$/.test(event.currentTarget.value)) {	//allow @, commas, or integers
				setViIndex(event.currentTarget.value)
		}
		return true;
	}

	const scenIndexHandler = (event) => {
		if (!event.currentTarget.value || /^[,0-9]+$/.test(event.currentTarget.value)) {	//allow integers or commas
			setScenIndex(event.currentTarget.value)
		}
		return true;
	};

	const keyHandler = (event) => {
		if(event.key === 'Enter') {
			loadResults(viIndex, scenIndex, checkReadout)
		}
	}

	// handle readout selection dropdown
	const readoutHandler = (event) => {	
		let newReadout = event.currentTarget.textContent;

		if ("Measurements" === newReadout) {
			if ('Measurements' === checkReadout) {
				newReadout = '';
			}
			setCheckReadout(newReadout);
			setSvgURL('')
		} else if ("Metrics" === newReadout) {
			if ('Metrics' === checkReadout) {
				newReadout = '';
			}
			setCheckReadout(newReadout);
			setSvgURL('')
		} else {
			if (event.ctrlKey && checkReadout) {
				if ("Measurements" === checkReadout || "Metrics" === checkReadout) {
					setCheckReadout(newReadout)
					setSvgURL('')
				} else {
					if (!checkReadout.includes(newReadout)) {
						newReadout = checkReadout + ',' + newReadout
						setCheckReadout(newReadout)
					}	
				}
			} else {
				if (newReadout === checkReadout) {
					newReadout = ''
				}
				setCheckReadout(newReadout);
			}
		}
		loadResults(viIndex, scenIndex, newReadout);	
	};


	// convers a list of values [x,y,z] into  [{'value':x},{'value':y},{'value':z}] for use by Slider
	function makeMarksArray(valueList) {
		return valueList.map((x) => ({value:x}) );
	}

	const handleSlider = name => (event, value) => {
		sliderInfo.selection[name] = sliderInfo.extents[name].indexOf(value);
		setSliderInfo(sliderInfo);

		//calculate viIndex from selection array
		let newIndex = Object.keys(sliderInfo.selection).reduce(
			(accumulator, axisName) => accumulator + (sliderInfo.offsets[axisName] * sliderInfo.selection[axisName]),
			1,
		);		
		setViIndex(newIndex);
		loadResults(newIndex, scenIndex, checkReadout);
	};


	const afterInjection = useCallback(() => {		
		setIsImageLoading(false);
	}, [setIsImageLoading]);
	

	const svgError = (error) => {	
		setIsImageLoading(false);		
		console.log('svg error')
	};

	function loadResults(viIndex, scenIndex, readout) {
		if (props.apiCxt.apiToken && props.contentPK && 'sim-results'=== props.activeTab ) {
			//setHtmlData("");
			setMeasurementsData("");
			setMetricsData({});

			if ('Measurements' === readout || 'Metrics' === readout) {
				setIsImageLoading(true);		

				try {
					let url = props.apiCxt.endpoint + props.contentPK.replace("$", "/") + "/results?&viIndex=" + viIndex + "&scenIndex=" + scenIndex + "&api-token="+props.apiCxt.apiToken
					
					if ('Measurements' === readout) {
						url += '&measurements';
					} else {
						url += '&metrics';
					}
					fetch(
						url
					).then(response => {
						return response.json();
					}).then(respData => {
						setIsImageLoading(false);

						if ('exception' in respData) {
							alert(respData['exception'])
							return;
						}

						if ('Measurements' === readout) {
							//transpose data so it's digestible into columns and rows for easier table building
							let	headers = [];
							let rows = {};

							//initialize the header list and rows map
							for (let ix = 0; ix < respData['measurements'].length; ++ix) {
								let 	measItem = respData['measurements'][ix];
								const	header = measItem['viIndex'] + '/' + measItem['scenIndex'];
								
								headers.push(header);
								//initialize the rows map from the first item
								if (0 === ix) {
									Object.keys(measItem['values']).map((measName) => {
										rows[measName] = [];
										return null;
									})
								}
							}
							//append values onto each row
							for (let ix = 0; ix < respData['measurements'].length; ++ix) {
								let 	measItem = respData['measurements'][ix];

								Object.keys(measItem['values']).map((measName) => {
									rows[measName].push(measItem['values'][measName])
									return null;
								})																	
							}
							setMeasurementsData({'headers':headers, 'rows':rows});
						} else {
							setMetricsData(respData['metrics'][0]);
						}		
					});	
				} catch (error) {
					console.log('There was an error', error);
					setIsImageLoading(false);
				};
			} else {
				if (readout) {
					let url = props.apiCxt.endpoint + props.contentPK.replace("$", "/") + "/results?plot&viIndex=" + viIndex + "&scenIndex=" + scenIndex + "&readouts=" + readout + "&api-token="+props.apiCxt.apiToken
				
					setIsImageLoading(true);
					setSvgURL(url)
/*
					setIsImageLoading(true);		

					try {

						let url = props.apiCxt.endpoint + props.contentPK.replace("$", "/") + "/results?plot&viIndex=" + viIndex + "&scenIndex=" + scenIndex + "&readouts=" + readout + "&api-token="+props.apiCxt.apiToken
						
						fetch(
							url
						).then(response => {
							return response.text();
						}).then(respData => {
							setIsImageLoading(false);
							setHtmlData(respData);			
						});	
					} catch (error) {
						console.log('There was an error', error);
						setIsImageLoading(false);
					};
*/
				}
			}
		}
	}


	//parse folderJSON into folderMap, itemMap
	function makeMeasurementsTable() {
		return 	<TableContainer component={Paper}>
			<Table size="small" aria-label="a dense table">
				<TableHead>
					<TableRow>
						<TableCell align="left" style={{'fontWeight':'bold'}} >Measurement</TableCell>
						{
							measurementsData['headers'] && measurementsData['headers'].map((header, i) => {
								return <TableCell key={'h-'+i} align="left" style={{'fontWeight':'bold'}} >{header}</TableCell>
							})
						}
					</TableRow>
				</TableHead>
				<TableBody>
					{
						measurementsData['rows'] && Object.keys(measurementsData['rows']).map((rowKey, i) => {		
							return <TableRow key={'t-'+i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell align="left" >{rowKey}</TableCell>
									{
										measurementsData['rows'][rowKey].map((value, i) => {
											return <TableCell key={'r-'+i} align="left" >{value}</TableCell>
										})
									}
								</TableRow>
						})
					}
				</TableBody>
			</Table>
		</TableContainer>
	}

	
	return(
		<div>
			<div style={{'marginTop':'8px'}}>
				<Card style={{'marginTop':'8px'}}>
					{ !resultsOpen && <span onClick={handleResultsArrow} ><ArrowRightIcon /></span> }
					{ resultsOpen && <span onClick={handleResultsArrow}><ArrowDropDownIcon /></span> }
					<Button variant="text" onClick={resultsHandler}>Results</Button>
					<span>{isLoading ? 'Loading...' : ''}</span>								
					{ resultsOpen && <TableContainer component={Paper}>
							<Table size="small" aria-label="a dense table">
								<TableHead>
									<TableRow>
										<TableCell align="left" style={{'fontWeight':'bold'}} >Submit Time</TableCell>
										<TableCell align="right" style={{'fontWeight':'bold'}} >VIs</TableCell>
										<TableCell align="right" style={{'fontWeight':'bold'}} >Scenarios</TableCell>
										<TableCell align="right" style={{'fontWeight':'bold'}} >Simulations</TableCell>
										<TableCell align="right" style={{'fontWeight':'bold'}} >Remaining</TableCell>
										<TableCell align="right" style={{'fontWeight':'bold'}} >Completed</TableCell>
										<TableCell align="right" style={{'fontWeight':'bold'}} >Errors</TableCell>
										<TableCell align="right" style={{'fontWeight':'bold'}} >SQS</TableCell>
										<TableCell align="right" style={{'fontWeight':'bold'}} >
											<Button variant="text" onClick={sliceHandler}>Slices</Button>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
										<TableCell align="left">{gridStatus && gridStatus['last_submit_time']}</TableCell>
										<TableCell align="right">{gridStatus && gridStatus['vi_count']}</TableCell>
										<TableCell align="right">{gridStatus && gridStatus['scen_count']}</TableCell>
										<TableCell align="right">{gridStatus && gridStatus['sim_count']}</TableCell>
										<TableCell align="right">{isLoading ? 'Loading...' : gridStatus && gridStatus['remaining_sim_count']}</TableCell>
										<TableCell align="right">{gridStatus && gridStatus['sim_count'] && gridStatus['completed']}%</TableCell>
										<TableCell align="right">{gridStatus && gridStatus['error_sim_count']}</TableCell>
										<TableCell align="right">{gridStatus && gridStatus['submit_message_count']}</TableCell>
										<TableCell align="right">
											{isSliceLoading ? 'Loading...' : <Tooltip title="Completed/Total"><span>{sliceStatus}</span></Tooltip>}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
							<div style={{'width':'100%', 'height':'36px'}}>
									<Button type="button" color="error" onClick={handleDeleteResults} style={{'position':'absolute', 'right':'0'}}>Delete Results...</Button>
								</div>
						</TableContainer>
					}
				</Card>

				<Card style={{'marginTop':'8px'}}>
					{ !batchOpen && <span onClick={handleBatchArrow} ><ArrowRightIcon /></span> }
					{ batchOpen && <span onClick={handleBatchArrow}><ArrowDropDownIcon /></span> }
					<Button variant="text" onClick={batchHandler}>Batch</Button>
					<span>{isBatchLoading ? 'Loading...' : ''}</span>								

					{ batchOpen && <div>
							<TableContainer component={Paper}>
								<Table size="small" aria-label="a dense table">
								<TableHead>
									<TableRow>
										<TableCell align="left" style={{'fontWeight':'bold'}} >Job Queue</TableCell>
										<TableCell align="right" style={{'fontWeight':'bold'}} >Submitted</TableCell>
										<TableCell align="right" style={{'fontWeight':'bold'}} >Pending</TableCell>
										<TableCell align="right" style={{'fontWeight':'bold'}} >Runnable</TableCell>
										<TableCell align="right" style={{'fontWeight':'bold'}} >Starting</TableCell>
										<TableCell align="right" style={{'fontWeight':'bold'}} >Running</TableCell>
										<TableCell align="right" style={{'fontWeight':'bold'}} >Completed</TableCell>
										<TableCell align="right" style={{'fontWeight':'bold'}} >Failed</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
										{ Object.keys(batchStatus).map((queueName) => {
											return 	<TableRow key={queueName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell align="left">{queueName}</TableCell>
												<TableCell align="right">{batchStatus[queueName]['SUBMITTED']}</TableCell>
												<TableCell align="right">{batchStatus[queueName]['PENDING']}</TableCell>
												<TableCell align="right">{batchStatus[queueName]['RUNNABLE']}</TableCell>
												<TableCell align="right">{batchStatus[queueName]['STARTING']}</TableCell>
												<TableCell align="right">{isBatchLoading ? 'Loading...' : batchStatus[queueName]['RUNNING']}</TableCell>
												<TableCell align="right">{batchStatus[queueName]['SUCCEEDED']}</TableCell>
												<TableCell align="right">{batchStatus[queueName]['FAILED']}</TableCell>
											</TableRow>
										})}
								</TableBody>
							</Table>
						</TableContainer>
						<div style={{'width':'100%', 'height':'36px'}}>
							<Button type="button" color="error" onClick={handleTerminateJobs} style={{'position':'absolute', 'right':'0'}}>Terminate Jobs...</Button>
						</div>
					</div>}
					<RequestDialog open={reqDlgOpen} setOpen={setReqDlgOpen} dlgProps={reqDlgProps} refreshCallback={props.refreshCallback} apiCxt={props.apiCxt} />
				</Card>
			</div>
			<Card style={{'marginTop':'8px'}}>
				<table>
					<tbody><tr>
						<td>
							<TextField style={{'marginRight':'8px'}}
								id={'viIndex'}
								label="VI Index"
								type="text"
								variant="standard"
								value={viIndex}
								onChange={viIndexHandler} 
								onKeyUp={keyHandler}
								ref={viIndexRef} 
							/>
							</td>
						<td>	
							<TextField style={{'marginRight':'8px'}}
								id={'scenIndex'}
								label="Scenario Index"
								type="text"
								variant="standard"
								value={scenIndex}
								onChange={scenIndexHandler} 
								onKeyUp={keyHandler}
								ref={scenIndexRef} 
							/>
							</td>
						<td>
							<span >
								<button className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
									Readout: {checkReadout}
								</button >
								<ul className="dropdown-menu" style={{'height':'300px','overflowX':'hidden','overflowY':'auto'}}>
									<li key="measurements" ><button className={'dropdown-item ' + (checkReadout==='Measurements' ? css.dropdownItemChecked : '')} href="#"  onClick={readoutHandler} >Measurements</button ></li>
									<li key="metrics" ><button className={'dropdown-item ' + (checkReadout==='Metrics' ? css.dropdownItemChecked : '')} href="#"  onClick={readoutHandler} >Metrics</button ></li>
									<li key="none" ><hr className="dropdown-divider"/></li>
									{ dataReadouts.current && dataReadouts.current.map((readout) => {
											return <li key={readout} ><button className={'dropdown-item ' + (checkReadout.includes(readout) ? css.dropdownItemChecked : '')} href="#"  onClick={readoutHandler} >{readout}</button ></li>
										})
									}
								</ul>
							</span>	
						</td>
					</tr></tbody>
				</table>
				{ isImageLoading && <section style={{'position':'absolute'}}><p>Loading... </p></section>}
				{ svgURL && <div id={props.page + '_content'} style={{'position':'relative', 'overflow':'auto'}}>
							<ReactSVG id={props.page + '_plot'} style={{'minWidth':'432px','minHeight':'432px'}} src={svgURL} ref={svgRef} afterInjection={afterInjection} onError={svgError} /> 
							<div style={{'position':'absolute', 'top':'32px', 'left':'632px'}}>
								{ Object.keys(sliderInfo.extents).map((key) => {
										return <div style={{'position':'relative'}} key={key}>
											<Slider style={{'width':'180px'}} 
												onChangeCommitted={handleSlider(key)}
												name={key}
												aria-label={key}
												step={null}
												defaultValue={sliderInfo.extents[key][0]}
												min={sliderInfo.extents[key][0]}
												max={sliderInfo.extents[key][sliderInfo.extents[key].length-1]}
												valueLabelDisplay="auto"
												marks={makeMarksArray(sliderInfo.extents[key])}
											/>
											<span style={{'position':'absolute','marginLeft':'12px'}}>{key}</span>
										</div>
									})
								}
							</div>
				</div>}
				{ ('Measurements' === checkReadout) && makeMeasurementsTable() }
				{ ('Metrics' === checkReadout) && <PropertyEditor page={props.page} contentObject={metricsData} apiCxt={props.apiCxt} bcRoot='metrics' /> }
			</Card>
		</div>
	)
}

export default SimResultsTab;


import { useState, useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state'

import DocObject from './DocObject';
import CreateDialog from '../../UI/CreateDialog';


function DocObjectList(props) {
	const	[documents, setDocuments] = useLocalStorageState('platformPage_documents', { defaultValue: [] })
	const	[selectedDocID, setSelectedDocID] = useState("")
	const	[selectedNote, setSelectedNote] = useState({})
	const	[reflinkMap, setReflinkMap] = useState({})	//map note_id->reflink for objects associated with selected tag
	const	[createDlgOpen, setCreateDlgOpen] = useState(false);

	const	dlgOpen = () => { setCreateDlgOpen(true); }
	const	dlgClose = () => { setCreateDlgOpen(false); }

	const grayIconClick = (event) => {
		let splits = event.currentTarget.id.split(':')	//id is document_id:note_id
		let document = props.tagMap['docMap'][splits[0]]

		props.setSelectedReflink('');
		if (document) {
			let note = props.tagMap.noteMap[splits[1]]

			setSelectedNote(note)
			dlgOpen();
		}
	}

	const blueIconClick = (event) => {
		let splits = event.currentTarget.id.split(':')
		let reflink = reflinkMap[splits[1]]
		let note = props.tagMap.noteMap[splits[1]]

		props.setSelectedReflink(reflink !== props.selectedReflink ? reflink : '');
		setSelectedNote(note)
/*
		//find associated note_id
		for (let noteID of Object.keys(reflinkMap)) {
			let reflink = reflinkMap[noteID]

			if (reflink.PK === event.currentTarget.id) {
				let note = props.tagMap.noteMap[noteID]
				
				setSelectedNote(note)
				props.setSelectedReflink(reflink);
				break;
			}
		}
*/
	}

	//handle ok from create dialog
	const createRefHandler = (newIDValue, newNameValue) => {
		setCreateDlgOpen(false);

		let document = props.tagMap.docMap[selectedNote.document_id]
		let isDocNote = selectedNote.note_id === selectedNote.document_id;
		let postData = {}
		
		postData['platform_id'] = props.apiCxt.platform;
		postData['document'] = document;
		postData['note'] = selectedNote;
		postData['name'] = postData['note']['note_text']
		postData['meta_json'] = {}
		postData['tags'] = {};

		// convert tag list into tag map
		let tagList = isDocNote ? document.tags : selectedNote.tags;

		for (let tag of tagList) {
			let tagSplits = tag.split(':')
			let tagName = tagSplits[0]
			let tagValue = (1 < tagSplits.length) ? tagSplits[1] : '';

			postData['tags'][tagName] = tagValue;
		}
		try {
			var url = props.apiCxt.endpoint + 'reflinks?notify=none&platform_id=' + props.apiCxt.platform + '&api-token=' + props.apiCxt.apiToken
			
			if (newIDValue) {
				url += '&new_id=' + newIDValue;
			}
			if (props.apiCxt.account) {
				url += '&account=' + props.apiCxt.account
			}

			fetch(
				url, 
				{method: "POST", body: JSON.stringify(postData)}
			).then(response => {
				return response.json();
			}).then(data => {
				if ('reflinks' in data) {
					let reflink = data['reflinks'][0];

					props.setSelectedReflink(reflink)
					//set reflinkMap so blue icon appears
					reflinkMap[reflink['note']['note_id']] = reflink;
					setReflinkMap(reflinkMap);
				 } else if ('exception' in data) {
					let msg = 'exception: ' + data['exception']
					
					console.log(msg);
					alert(msg)
				}
			});	
		} catch (error) {
			console.log('There was an error', error);
		};
		return false;
	}

	// set up reflink map
	useEffect(()=> {
		if (props.tagExpr && 'tagNotesMap' in props.tagMap) {
			//get all reflinks for selected tag(s)
			try {
				//turn x,y into x,,y so it's an OR tag query
				//let tagQuery = props.selectedTag.replace(",", ",,")
				var url = props.apiCxt.endpoint + 'reflinks?select=*&notify=none&platform_id=' + props.apiCxt.platform + '&tags=' + props.tagExpr + '&api-token=' + props.apiCxt.apiToken
				
				if (props.apiCxt.account) {
					url += '&account=' + props.apiCxt.account
				}
	
				fetch(
					url, 
					{method: "GET"}
				).then(response => {
					return response.json();
				}).then(data => {
					if ('reflinks' in data) {
						let rflm = {}

						for (let reflink of data['reflinks']) {
							rflm[reflink['note']['note_id']] = reflink
						}
						setReflinkMap(rflm);
					} else if ('exception' in data) {
						let msg = 'exception: ' + data['exception']
						
						console.log(msg);
						alert(msg)
					}
				});	
			} catch (error) {
				console.log('There was an error', error);
			};

		}

		if (props.tagMap && 'tagNotesMap' in props.tagMap) {
			const splits = props.tagExpr.split(',')
			let tdMap = {}	//filtered map of cloned documents + note lists	

			for (const tag of splits) {
				if (tag in props.tagMap['tagNotesMap']) {
					// find reflinks for selected tag(s)
			
					for(let noteID of props.tagMap['tagNotesMap'][tag]) {
						let note = props.tagMap['noteMap'][noteID];
						let documentID = note['document_id']
						let docClone = null;
						
						if (documentID in tdMap) {
							docClone = tdMap[documentID];
						} else {
							docClone = JSON.parse(JSON.stringify(props.tagMap['docMap'][note['document_id']]));	//create a deep copy of document
							docClone['notes'] = {}
							tdMap[documentID] = docClone
						}
						docClone['notes'][noteID] = note							
					}
				}
			}
			setDocuments(Object.values(tdMap));
		} else {
			setDocuments([])
			setReflinkMap({})
		}
	}, [props, setDocuments, setReflinkMap])


	return <div >
		<div style={{width:'100%','backgroundColor':'#EEEEEE'}}>
			<div style={{'position':'absolute','textAlign':'center','width':'100%','height':'26px','borderBottom':'1px solid gray'}} >References</div>
			<div style={{'position':'absolute','top':'26px','bottom':'0','width':'100%','overflow':'auto'}}>
				{ documents.map((document,i) => {
					return <DocObject 
							key={document.document_id+i} 
							tagNotesMap={props.tagMap['tagNotesMap']}
							doc={document} 
							selectedDocID={selectedDocID} 
							setSelectedDocID={setSelectedDocID}
							newReflinkHandler={grayIconClick}
							openReflinkHandler={blueIconClick}
							reflinkMap={reflinkMap}
						></DocObject>
					})
				}
			</div>
		</div>
		<CreateDialog open={createDlgOpen} handleClose={dlgClose} handleCreate={createRefHandler} name={selectedNote.note_text} new_id={selectedNote.note_id} objectClass="reflinks" apiCxt={props.apiCxt} />
	</div>
}

export default DocObjectList;


import { useState, useEffect} from 'react';
import useLocalStorageState from 'use-local-storage-state'

import ContentTabs from '../components/ContentTabs';

import './react-split-pane.css';


function PlatformPage(props) {
	const 	[contentPK, setContentPK] = useLocalStorageState('platformPage_contentPK', { defaultValue: "" })
	const	[tagMap, setTagMap] = useState({'tagNotesMap':{}, 'noteMap':{}, 'docMap':{}})
	const	[tagExpr, setTagExpr] = useLocalStorageState('platformPage_tagExpr', { defaultValue: "" })
	//const	[selectedReflink, setSelectedReflink] = useState({})


	//get TagMap
	useEffect(()=> {
		setTagMap({'tagNotesMap':{}, 'noteMap':{}, 'docMap':{}})

		if (props.apiCxt.apiToken && props.apiCxt.platform) {
			try {
				var url = props.apiCxt.endpoint + 'platforms/' + props.apiCxt.platform + '/tagMap?notify=none&limit=1&api-token=' + props.apiCxt.apiToken
				
				if (props.apiCxt.account) {
					url += '&account=' + props.apiCxt.account
				}

				fetch(
					url
				).then(response => {
					return response.json();
				}).then(data => {
					if ('tagNotesMap' in data) {
						// tagMap contains {tagNotesMap:{...}, noteMap:{...}, docMap:{...}}
						let tagMap = data;
						let tagNotesMap = tagMap['tagNotesMap']
						let sortedKeys = Object.keys(tagNotesMap).sort(function (a, b) {
							return a.toLowerCase().localeCompare(b.toLowerCase());
						});
						let sortedMap = sortedKeys.reduce((sm, sortedKey) => {
							sm[sortedKey] = tagNotesMap[sortedKey]
							return sm							
						}, {});

						tagMap['tagNotesMap'] = sortedMap
						setTagMap(tagMap)
 					} else if ('exception' in data) {
						let msg = 'exception: ' + data['exception']
						
						console.log(msg);
						//document.getElementById(props.page + '_' + props.parentClass + '_loading').textContent = msg;
						//alert(msg)
					}
				});	
			} catch (error) {
				console.log('There was an error', error);
				//document.getElementById(props.page + '_' + props.parentClass + '_loading').textContent = error;
			};
		} else {
			setContentPK("")
			setTagMap({'tagNotesMap':{}, 'noteMap':{}, 'docMap':{}})
			setTagExpr("")
		}
	}, [props, setContentPK, setTagMap, setTagExpr])

/*
	//clear selectedReflink if tagExpr changes
	useEffect(()=> {
		setSelectedReflink({})
	}, [props.apiCxt, tagExpr])


	// completion routines for modal dialogs called in PropertyEditor, DocObjectList
	// dlgProps is {method:'', url:'', data:null, object:{}}
	const refreshCallback = useCallback((dlgProps) => {
		if ('DELETE' === dlgProps.method) {
			if (dlgProps.object === selectedReflink) {
				setSelectedReflink({})
			}
		}
	}, [selectedReflink]);
*/

	//populate platform object
	useEffect(()=> {
		setContentPK('')
		setTagExpr('')

		if (props.apiCxt.apiToken && props.apiCxt.platform) {
			try {
				var url = props.apiCxt.endpoint + 'platforms/' + props.apiCxt.platform + '?notify=none&limit=1&api-token=' + props.apiCxt.apiToken
				
				if (props.apiCxt.account) {
					url += '&account=' + props.apiCxt.account
				}

				fetch(
					url
				).then(response => {
					return response.json();
				}).then(data => {
					if ('platforms' in data) {
						let platforms = data['platforms'];

						if (0 < platforms.length) {
							let platform = platforms[0];

							setContentPK(platform['PK'])
							//if ('network' in platform) {
							//	setNetwork(platform['network'])
							//}
						}
						else {
							setContentPK('')
						}
 					} else if ('exception' in data) {

						let msg = 'exception: ' + data['exception']
						
						console.log(msg);
						//document.getElementById(props.page + '_' + props.parentClass + '_loading').textContent = msg;
						//alert(msg)
						props.apiCxt.platform = ''
						props.setApiCxt(props.apiCxt)
					}
				});	
			} catch (error) {
				console.log('There was an error', error);
				//document.getElementById(props.page + '_' + props.parentClass + '_loading').textContent = error;
			};
		}
	}, [props, setContentPK, setTagExpr])


	return <div style={{'position':'absolute','width':'100%','top':'63px', 'bottom':'0px'}} >
			<div>
				<ContentTabs contentPK={contentPK} tagMap={tagMap} tagExpr={tagExpr} page='platformPage' apiCxt={props.apiCxt}></ContentTabs>
			</div>	

	</div>
}

export default PlatformPage;

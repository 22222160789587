
import { useState, useRef, useEffect } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function CreateDialog(props) {
	const newIDRef = useRef();
	const [newIDValue, setNewIDValue] = useState("")
	const newNameRef = useRef();
	const [newNameValue, setNewNameValue] = useState("")
	const gridIDRef = useRef();
  
	//handle Create ID text field
	const newIDHandler = (event) => {
		//if (!event.currentTarget.value || /^\d+$/.test(event.currentTarget.value)) {	//allow empty string or integers
		if (!event.currentTarget.value || /^[_a-zA-Z0-9]+$/.test(event.currentTarget.value)) {	//allow integers or commas
			setNewIDValue(event.currentTarget.value)
		}
		return false;
	}

	const newNameHandler = (event) => {
		setNewNameValue(event.currentTarget.value)
		return false;
	}

	const createBtnHandler = (event) => {
		props.handleCreate(newIDValue, newNameValue)
	}

	//make sure inputs are loaded first time through
	useEffect(()=> {
		if ('name' in props) {
			setNewNameValue(props['name'])
		}
		if ('new_id' in props) {
			setNewIDValue(props['new_id'])
		}
	}, [props])

	return(
		<Dialog open={props.open} onClose={props.setOpen}>
		<DialogTitle>Create {props.objectClass.substring(0,props.objectClass.length-1)}</DialogTitle>
		<DialogContent>
			<DialogContentText>
				If no ID is specified a random one will be genarated.
			</DialogContentText>
			<TextField
				autoFocus
				margin="dense"
				id={props.page + '_' + props.objectClass + '_newID'}
				label="new object ID"
				type="text"
				fullWidth
				variant="standard"
				ref={newIDRef}
				value={newIDValue}
				onChange={newIDHandler} 
			/>
			<TextField
				autoFocus
				margin="dense"
				id={props.page + '_' + props.objectClass + '_newName'}
				label="object name"
				type="text"
				fullWidth
				variant="standard"
				ref={newNameRef}
				value={newNameValue}
				onChange={newNameHandler} 
			/>
			{ 'posteriors' === props.objectClass && <TextField
				autoFocus
				margin="dense"
				id={props.page + '_' + props.objectClass + '_grid_id'}
				label="grid_id"
				type="text"
				fullWidth
				variant="standard"
				ref={gridIDRef}
			/>

			}
		</DialogContent>
		<DialogActions>
			<Button onClick={createBtnHandler}>Create</Button>
			<Button onClick={props.handleClose}>Cancel</Button>
		</DialogActions>
	</Dialog>
);
}

export default CreateDialog;


import React from 'react';
import { useState, useEffect } from 'react';

import useLocalStorageState from 'use-local-storage-state'

import Button from '@mui/material/Button';

//import DiagramTab from './DiagramTab';
import FilesTab from './FilesTab';
import LogsTab from './LogsTab';
import NetworkTab from './NetworkTab';
import PropertyEditor from './PropertyEditor';
import ReflinksTab from './ReflinksTab';
import ResultsTab from './ResultsTab';
import SimResultsTab from './SimResultsTab';

import RequestDialog from '../UI/RequestDialog';

import css from './ContentTabs.module.css'

function ContentTabs(props) {
	const	[contentPK] = useLocalStorageState(props.page + '_contentPK', { defaultValue: "" });
	const	[contentObject, setContentObject] = useLocalStorageState(props.page + '_contentObject', { defaultValue: {} })
	const	[activeTab, setActiveTab] = useState('')
	const	[isLoading, setIsLoading] = useState(true);
	const	[objectClass, setObjectClass] = useState('')
	const	[reqDlgOpen, setReqDlgOpen] = useState(false);
	const	[reqDlgProps, setReqDlgProps] = useState({method:'', url:'', data:null});
	const	resultClasses = ['posteriors', 'analytics'];	// classes that have results tabs
	//const	diagramClasses = ['platforms', 'models'];	// classes that have diagram tabs
	const	fileClasses = ['platforms','models', 'grids'];	//classes that have file storage
	const	logClasses = ['models', 'grids', 'subjects', 'posteriors', 'analytics'];	//classes that have log tabs
	const	objectID = (1 < contentPK.split(":").length) ? contentPK.split(":")[1] : ''

	
	const setRequestURL = (method, suffix, queryParams, data) => {
		let url = props.apiCxt.endpoint + objectClass + '/' + contentPK + suffix + '?notify=none&account=' + props.apiCxt.account + '&api-token=' + props.apiCxt.apiToken
		
		if (queryParams) {
			url += '&' + queryParams
		}
		reqDlgProps.method = method
		reqDlgProps.url = url
		reqDlgProps.data = data
		setReqDlgProps(reqDlgProps);
	}

	const handleTabClick = (event) => {
		let	tabName = event.currentTarget.id.substring(0, event.currentTarget.id.length-6);	// remove trainling '-label'

		setActiveTab(tabName)
	};
  
	const handleModelCompile = () => {
		setRequestURL('POST', '', 'compile&network', null)
		setReqDlgOpen(true);
	};
  
	const handleGridRun = () => {
		setRequestURL('POST', '/results', 'submit&batch', null)
		setReqDlgOpen(true);
	};
  
	const handleSubmit = () => {
		setRequestURL('POST', '', 'submit&batch', null)
		setReqDlgOpen(true);
	};

	const handleRefreshLibrary = () => {
		setRequestURL('POST', '/tagMap', '', null)
		setReqDlgOpen(true);
	};


	const handleDeleteLogs = () => {
		let url = props.apiCxt.endpoint + 'logs/' + contentPK + '?notify=none&account=' + props.apiCxt.account + '&api-token=' + props.apiCxt.apiToken
		
		reqDlgProps.method = 'DELETE';
		reqDlgProps.url = url
		setReqDlgProps(reqDlgProps);
		setReqDlgOpen(true);
	};

	useEffect(()=> {
		const splits = contentPK.split('$')
		
		setObjectClass(splits[0])
	}, [contentPK, objectClass, props.apiCxt])


	useEffect(()=> {
 		if (props.apiCxt.apiToken && props.contentPK) {
			//check for empty ID (grid root)
			var accountSplits = props.contentPK.split(':')

			if (1 === accountSplits.length || !accountSplits[1]) {
				setContentObject({});	
				return;
			}
			setIsLoading(true);
			//document.getElementById('search-input').value = props.contentPK;
			try {
				var classSplits = props.contentPK.split('$')
				var objectClass = classSplits[0]
				var url = props.apiCxt.endpoint + objectClass + '/' + classSplits[1] + '?account=' + props.apiCxt.account + '&api-token=' + props.apiCxt.apiToken
				
				fetch(
					url
				).then(response => {
					return response.json();
				}).then(data => {
					if (objectClass in data) {
						let rsltObj = data[objectClass][0];

						setIsLoading(false);
						setContentObject(rsltObj);
					} else if ('exception' in data) {
                        let msg = 'exception: ' + data['exception']
                        
						console.log(msg);
						let loadingElement = document.getElementById(props.contentPK + '_loading');

						if (loadingElement) {
							loadingElement.textContent = msg;
						}
						setContentObject({});
					}	
				});	
			} catch (error) {
				console.log('There was an error', error);
				let loadingElement = document.getElementById(props.contentPK + '_loading');

				if (loadingElement) {
					loadingElement.textContent = error;
				}
				setContentObject({});
			};
		}
	}, [setContentObject, props.contentPK, props.apiCxt])

	return (
		<div className={css.contentTabs} >
			<div className="row" >
				<ul className="nav nav-tabs mb-3" id="ex1" role="tablist" >
					<li className={css.tabControl} ></li>
					{	objectID &&
						<li className="nav-item" role="presentation">
							<a
								className="nav-link active"
								id="properties-label"
								data-bs-toggle="tab"
								href="#properties-tab"
								role="tab"
								aria-controls="properties-tab"
								aria-selected="true"
								onClick={handleTabClick}
							>{objectClass.charAt(0).toUpperCase() + objectClass.slice(1,-1)}</a>
						</li>
					}
					{ ('grids' === objectClass) && objectID &&
						<li className="nav-item" role="presentation">
							<a
								className="nav-link"
								id="sim-results-label"
								data-bs-toggle="tab"
								href="#sim-results-tab"
								role="tab"
								aria-controls="sim-results-tab"
								aria-selected="false"
								onClick={handleTabClick}
							>Simulations</a>
						</li>
					}
					{ resultClasses.includes(objectClass) && objectID &&
						<li className="nav-item" role="presentation">
							<a
								className="nav-link"
								id="results-label"
								data-bs-toggle="tab"
								href="#results-tab"
								role="tab"
								aria-controls="results-tab"
								aria-selected="false"
								onClick={handleTabClick}
							>{objectClass==="posteriors" ? "Distribution" : "Plot"}</a>
						</li>
					}
					{ ('posteriors' === objectClass) && objectID &&
						<li className="nav-item" role="presentation">
							<a
								className="nav-link"
								id="marginals-label"
								data-bs-toggle="tab"
								href="#marginals-tab"
								role="tab"
								aria-controls="marginals-tab"
								aria-selected="false"
								onClick={handleTabClick}
							>Axis Marginals</a>
						</li>
					}
					{ contentObject && 'network' in contentObject &&
						<li className="nav-item" role="presentation">
							<a
								className="nav-link"
								id="network-label"
								data-bs-toggle="tab"
								href="#network-tab"
								role="tab"
								aria-controls="network-tab"
								aria-selected="false"
								onClick={handleTabClick}
							>Network</a>
						</li>
					}
					{ (props.apiCxt.platform && contentObject && ('tagExpr' in props  || 'tags' in contentObject)) &&
						<li className="nav-item" role="presentation">
							<a
								className="nav-link"
								id="reflinks-label"
								data-bs-toggle="tab"
								href="#reflinks-tab"
								role="tab"
								aria-controls="reflinks-tab"
								aria-selected="false"
								onClick={handleTabClick}
							>Reflinks</a>
						</li>
					}
					{ fileClasses.includes(objectClass) && contentObject &&
						<li className="nav-item" role="presentation">
							<a
								className="nav-link"
								id="files-label"
								data-bs-toggle="tab"
								href="#files-tab"
								role="tab"
								aria-controls="files-tab"
								aria-selected="false"
								onClick={handleTabClick}
							>Files</a>
						</li>
					}
					{ logClasses.includes(objectClass)  && objectID &&
						<li className="nav-item" role="presentation">
							<a
								className="nav-link"
								id="logs-label"
								data-bs-toggle="tab"
								href="#logs-tab"
								role="tab"
								aria-controls="logs-tab"
								aria-selected="false"
								onClick={handleTabClick}
							>Logs</a>
						</li>
					}
				</ul>
				<div className="tab-content" style={{'position':'absolute','top':'41px','bottom':'10px','backgroundColor': 'rgb(243, 246, 249)'}}>
					<div className="tab-pane fade show active" id="properties-tab" role="tabpanel" aria-labelledby="properties-label">
						<div style={{'position':'absolute','top':'0px','bottom':'40px','left':'0px','right':'0px','overflowY':'auto'}}>
							<PropertyEditor page={props.page} activeTab={activeTab} contentPK={contentPK} contentObject={contentObject} setContentObject={setContentObject} isLoading={isLoading} refreshCallback={props.refreshCallback} bcRoot={objectClass.slice(0,-1)} apiCxt={props.apiCxt} />
						</div>
						<div style={{'position':'absolute','width':'100%','left':'0px','bottom':'0px','borderTop':'1px solid black','backgroundColor': 'white'}}>
							{objectClass==='platforms' && <Button type="button" onClick={handleRefreshLibrary}>Refresh Library...</Button>}
							{objectClass==='models' && <Button type="button" onClick={handleModelCompile} >Compile...</Button>}
						</div>
					</div>
					{ ('grids' === objectClass) && objectID &&
						<div className="tab-pane fade" id="sim-results-tab" role="tabpanel" aria-labelledby="sim-results-label">
							<div style={{'position':'absolute','top':'0px','bottom':'40px','left':'8px','right':'8px','overflow':'auto'}}>
								{objectClass==='grids' && <SimResultsTab activeTab={activeTab} contentPK={contentPK} contentObject={contentObject} page={props.page} apiCxt={props.apiCxt} />}
							</div>
							<div style={{'position':'absolute','width':'100%','left':'0px','bottom':'0px','borderTop':'1px solid black','backgroundColor': 'white'}}>
								{objectClass==='grids' && <Button type="button" onClick={handleGridRun} >Run Simulations...</Button>}
							</div>
						</div>
					}
					{ resultClasses.includes(objectClass) && objectID &&
						<div className="tab-pane fade" id="results-tab" role="tabpanel" aria-labelledby="results-label">
							<div style={{'position':'absolute','top':'0px','bottom':'40px','left':'8px','right':'8px','overflow':'auto'}}>
								<ResultsTab activeTab={activeTab} contentPK={contentPK} contentObject={contentObject} page={props.page} apiCxt={props.apiCxt} />
							</div>
							<div style={{'position':'absolute','width':'100%','left':'0px','bottom':'0px','borderTop':'1px solid black','backgroundColor': 'white'}}>
								{objectClass==='posteriors' && <Button type="button" onClick={handleSubmit}>Run Bayesian...</Button>}
								{objectClass==='analytics' && <Button type="button" onClick={handleSubmit} >Run Analytic...</Button>}
							</div>
						</div>
					}
					{ ('posteriors' === objectClass) && objectID &&
						<div className="tab-pane fade" id="marginals-tab" role="tabpanel" aria-labelledby="marginals-label">
							<div style={{'position':'absolute','top':'0px','bottom':'40px','left':'8px','right':'8px','overflow':'auto'}}>
								<ResultsTab activeTab={activeTab} contentPK={contentPK} contentObject={contentObject} page={props.page} apiCxt={props.apiCxt} />
							</div>
						</div>
					}
					{ contentObject && 'network' in contentObject &&
						<div className="tab-pane fade" id="network-tab" role="tabpanel" aria-labelledby="network-label">
							<NetworkTab activeTab={activeTab} contentPK={contentPK} contentObject={contentObject} page={props.page} apiCxt={props.apiCxt} />
						</div>
					}
					{ (props.apiCxt.platform && contentObject && ('tagExpr' in props  || 'tags' in contentObject)) &&
						<div className="tab-pane fade" id="reflinks-tab" role="tabpanel" aria-labelledby="reflinks-label">
							<ReflinksTab activeTab={activeTab} contentPK={contentPK} contentObject={contentObject} tagMap={props.tagMap} tagExpr={props.tagExpr} page={props.page} apiCxt={props.apiCxt} />
						</div>
					}
					{ fileClasses.includes(objectClass) && contentObject &&
						<div className="tab-pane fade" id="files-tab" role="tabpanel" aria-labelledby="files-label">
							<FilesTab activeTab={activeTab} contentPK={contentPK} contentObject={contentObject} page={props.page} apiCxt={props.apiCxt} />
						</div>
					}
					{  logClasses.includes(objectClass) && objectID &&
						<div className="tab-pane fade" id="logs-tab" role="tabpanel" aria-labelledby="logs-label">
							<div style={{'position':'absolute','top':'0px','bottom':'40px','left':'8px','right':'8px','overflow':'auto'}}>
							<LogsTab activeTab={activeTab} contentPK={contentPK} contentObject={contentObject} page={props.page} apiCxt={props.apiCxt} />
							</div>
							<div style={{'position':'absolute','width':'100%','left':'0px','bottom':'0px','height':'36px','borderTop':'1px solid black','backgroundColor': 'white'}}>
								<Button type="button" color="error" onClick={handleDeleteLogs} style={{'position':'absolute','right':'0'}}>Delete Logs...</Button>
							</div>
						</div>
					}
				</div>
			</div>
			<RequestDialog open={reqDlgOpen} setOpen={setReqDlgOpen} dlgProps={reqDlgProps} apiCxt={props.apiCxt} />
		</div>
	);
}

export default ContentTabs;
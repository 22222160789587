
import css from './V3Object.module.css';

/*
		var docHTML = '';
		
		docHTML += '<div class="docNote" >';
		docHTML += '<div id=' + this.modelName + '_doc_' + doc.document_id + ' class="docEmpty" >'; 
		
		var docTitleHTML = doc.title;
		var docAuthor = "";
		
		if ('url' in doc) {
			docTitleHTML = '<a href="' + doc.url + '" target="_blank">' + doc.title + '</a>';
		}
		
		if ('authors' in doc && 0 < doc.authors.length) {
			splits = doc.authors[0].split(" ");
			
			docAuthor = splits[splits.length-1];
		}
		
		docHTML += '<p id=' + this.modelName + '_docTitle_' + doc.document_id + ' class="docTitle" >' + docTitleHTML + '</p>';
		docHTML += '<p  class="docText" ><span class="authorText">' + docAuthor + '</span>&nbsp;&nbsp;<span>' + doc.date + '</span>&nbsp;-&nbsp;<span>' + doc.source + '</span></p>';
		docHTML += '</div>';
		docHTML += '<div id='+ this.modelName + '_notes_' + doc.document_id + ' class="noteBottom" style="display:none;">';				

		//add in notes area
		docHTML += '<div ></div>';
		
		docHTML += '</div></div>';
		
		rtn += docHTML;

*/

function DocObject(props) {

	function clickHandler(event) {
		if (props.doc.document_id !== props.selectedDocID) {
			event.preventDefault();
		}
		props.setSelectedDocID(props.doc.document_id);

		return true;
	}
	
	return(
		<li style={{'listStyle':'none'}}>
			<div style={props.selectedDocID === props.doc.document_id ? {'border':'2px solid black'} : {}}onClick={clickHandler}>
				<div className={css.docNote} >
					<div id={props.doc.document_id} className={css.docEmpty}>
						{ 'url' in props.doc && 
							<div className={css.docTitle} >
								<a href={props.doc.url} target="_blank" rel="noreferrer">{props.doc.title}</a>						
							</div>
						}
						{ !('url' in props.doc) && <div className={css.docTitle} >{props.doc.title}</div>}
						<div className={css.docText} >
							<div><span>{props.doc.date}</span>&nbsp;-&nbsp;<span>{props.doc.source}</span></div>
							{ 'authors' in props.doc && <span className={css.authorText}>{props.doc.authors}</span>
							}
						</div>
						<br/>
						<div id={props.doc.document_id + '_notes'} className={css.noteBottom} >
							{ 'notes' in props.doc && Object.values(props.doc['notes']).map((note,i) => {
									return <div key={'_note_' + i} className={props.selectedNoteID===note.note_id ? css.noteSelected : ''}>
										{i !== 0 && <hr style={{'height':'4px', 'margin':'4px'}} />}
										{ !(note.note_id in props.reflinkMap) && <img id={note.document_id + ':' + note.note_id} src="images/grayLink32.png" alt="" height="20" className={css.reflink} style={{'position':'absolute','right':'24px'}} onClick={props.newReflinkHandler}></img>}
										{ (note.note_id in props.reflinkMap) && <img id={note.document_id + ':' + note.note_id} src="images/blueLink32.png" alt="" height="20" className={css.reflink} style={{'position':'absolute','right':'24px'}} onClick={props.openReflinkHandler}></img>}
										<span style={{'fontStyle':'italic'}}>{note['modified']}</span>
										{ note.document_text && <div>{note.document_text}</div> }
										{ note.note_text && <div>{note.note_text}</div> }
									</div>
								})
							}
						</div>
					</div>
				</div>
			</div>
		</li>
	);
}


export default DocObject;

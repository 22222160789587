
import css from './V3Object.module.css';

function TagObject(props) {

	function clickHandler(event) {
		event.preventDefault();
		props.setTagExpr(props.object.name);
		return true;
	}
	
	return(
		<li style={{'listStyle':'none'}}>
			<div className={ props.tagExpr === props.object.name ? `${css.resourceItem} ${css.resourceSelected}` : `${css.resourceItem}`} style={props.tagExpr === props.object.name ? {'border':'2px solid black'} : {}}onClick={clickHandler}>
				<table style={{'width':'100%'}}>
					<tbody>
						<tr>
							<td><span className={css.resourceTitle}>{props.object.name}</span> </td> 
							<td style={{'textAlign':'center'}}></td> 
							<td style={{'textAlign':'right'}}>{props.object.count}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</li>
	);
}


export default TagObject;

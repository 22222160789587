

import * as React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';

import SplitPane from 'react-split-pane';

import Button from '@mui/material/Button';
import { TreeView } from '@mui/x-tree-view/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeItem, useTreeItem } from '@mui/x-tree-view/TreeItem';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';


function S3Uploader(props) {
	const	[isLoading, setIsLoading] = useState(false);		// flag for loading folder tree
	const	[folderMap, setFolderMap] = useState({});			// map containing folders and subfolders
	const	[activeFolder, setActiveFolder] = useState('files');		// currently selected folder
	const	[itemsLoading, setItemsLoading] = useState(false);	// flag for loading items 
	const	[items, setItems] = useState([])					// files for currently selected folder
	const	[allItemsCBox, setAllItemsCBox] = useState(false)	// state of checkbox in items table column header
	const 	allItemsCBoxRef = useRef();
	const	[checkedItems, setCheckedItems] = useState({});		// map of checkbox value booleans for each item name
	const	[checkedCount, setCheckedCount] = useState(0);		// number of checked items used for button status
	const	[dropFiles, setDropFiles] = useState([])			// list of files for upload
	const	[dropCount, setDropCount] = useState([])			// count of uploaded files
	const	[uploadDlgOpen, setUploadDlgOpen] = useState(false);	// upload dialog visibility
	const	[deleteDlgOpen, setDeleteDlgOpen] = useState(false);	// delete dialog visibility


	// ************** Folder Tree ******************
	const CustomContent = React.forwardRef(function CustomContent(props, ref) {
		const {
			classes,
			className,
			label,
			nodeId,
			icon: iconProp,
			expansionIcon,
			displayIcon,
		} = props;
	
		const {
			disabled,
			expanded,
			selected,
			focused,
			handleExpansion,
			handleSelection,
			preventSelection,
		} = useTreeItem(nodeId);
	
		const icon = iconProp || expansionIcon || displayIcon;
	
		const handleMouseDown = (event) => {
			preventSelection(event);
		};
	
		const handleExpansionClick = (event) => {
			handleExpansion(event);
		};
	
		const handleSelectionClick = (event) => {
			let parent1 = event.currentTarget.parentElement;
			let parent2 = parent1.parentElement;
			let s3_key = parent2.id;

			setActiveFolder(s3_key)
			handleSelection(event);
		};
	
		return (
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
			<div
				className={clsx(className, classes.root, {
					[classes.expanded]: expanded,
					[classes.selected]: selected,
					[classes.focused]: focused,
					[classes.disabled]: disabled,
				})}
				onMouseDown={handleMouseDown}
				ref={ref}
			>
				<div onClick={handleExpansionClick} className={classes.iconContainer}>
					{icon}
				</div>
				<Typography 
					onClick={handleSelectionClick}
					component="div"
					className={classes.label}
				>
					{label}
				</Typography>
			</div>
		);
	});
	
	function CustomTreeItem(props) {
	  return <TreeItem ContentComponent={CustomContent} {...props} />;
	}
	

	// ************** folder parsing ******************

	//parse folderJSON into folderMap, itemMap
	function makeTreeItems(folderMap, parentKey) {
		return Object.keys(folderMap).map((key) => {
			const	itemID = parentKey ? parentKey + '/' + key : key;
	
			return <CustomTreeItem id={itemID} key={itemID} nodeId={itemID} label={key} >{
				makeTreeItems(folderMap[key], itemID)
			}</CustomTreeItem>
		})
	}
	
	//parse folderJSON into folderMap, itemMap
	const parseFolderJSON = useCallback((folderJSON, parentKey, doFolders) => {
		let		rtn = {};
		const	keys = Object.keys(folderJSON);

		keys.map((key) => {
			const	itemID = parentKey + '/' + key
	
			if (doFolders) {
				if (folderJSON[key] instanceof Object) {
					rtn[key] = parseFolderJSON(folderJSON[key], itemID, doFolders)
				}
			} else {
				if (folderJSON[key] instanceof Object) {
					let newMap = parseFolderJSON(folderJSON[key], itemID, doFolders)

					rtn = {...rtn, ...newMap};
				} else {
					if (parentKey in rtn) {
						rtn[parentKey].push(itemID)
					} else {
						rtn[parentKey] = [itemID]
					}
				}	
			}
			return null;
		})
		return rtn;
	}, [])


	// ************** Drag & Drop ******************

	const handleFiles = useCallback((files) => {
		let newFiles = [...dropFiles, ...files];

		setDropFiles(newFiles);
		setDropCount(newFiles.length);
	}, [dropFiles, setDropFiles, setDropCount])


	const handleDrop = useCallback((event) => {
		let dt = event.dataTransfer
		let files = dt.files
		
		handleFiles(files)
	}, [handleFiles])
	
	const handleFileSelect = (event) => {
		let files = event.currentTarget.files;

		handleFiles(files)
	}

	const handleClear = useCallback((event) => {
		setDropFiles([]);
		setDropCount(0);
	}, [setDropFiles, setDropCount])

	useEffect(()=> {
		let dropArea = document.getElementById('drop-area')

		;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
			dropArea.addEventListener(eventName, preventDefaults, false)
		})
		
		function preventDefaults (e) {
			e.preventDefault()
			e.stopPropagation()
		}
		
		;['dragenter', 'dragover'].forEach(eventName => {
			dropArea.addEventListener(eventName, highlight, false)
		})
		
		;['dragleave', 'drop'].forEach(eventName => {
			dropArea.addEventListener(eventName, unhighlight, false)
		})
		
		function highlight(e) {
			dropArea.classList.add('highlight')
		}
		
		function unhighlight(e) {
			dropArea.classList.remove('highlight')
		}
			  
		dropArea.addEventListener('drop', handleDrop, false)
	
	}, [props.apiCxt, handleDrop])


	// ************** Upload ******************

	const handleFileUploadDlgOpen = () => {
		setUploadDlgOpen(true);
	};
  
	const handleFileUploadDlgClose = () => {
		setUploadDlgOpen(false);
	};
  
	const handleFileUpload = () => {
		let formData = new FormData()
	
		for(let ix = 0; ix < dropFiles.length; ix++) {
			formData.append(`file_${ix}`, dropFiles[ix]);
		}	
		if (props.apiCxt.apiToken && activeFolder) {
			try {
				let prefix = activeFolder;
				let subfolder = document.getElementById('subfolder').value;

				if (subfolder) {
					prefix = prefix + '/' + subfolder
				}
				let url = props.apiCxt.endpoint + prefix + "?PK=" + props.contentObject['PK'] + "&api-token="+props.apiCxt.apiToken
	
				fetch(
					url, 
					{method: "POST", body: formData}
				).then(response => {
					return response.json();
				}).then(data => {
					if ('uploaded' in data) {
						if (subfolder) {
							loadFolderTree();
							setActiveFolder(prefix);
						} else {
							loadItemsTable();
						}
 					} 
					if ('exception' in data) {
						let msg = 'exception: ' + data['exception']

						alert(msg);
					}
					setUploadDlgOpen(false);
					setDropFiles([]);
					setDropCount(0);
				});	
			} catch (error) {
				console.log('There was an error', error);
                alert(error)
			};
		}
	};
	
	// ************** Download ******************

	const handleDownload = () => {
		if (props.apiCxt.apiToken && 0 < checkedCount) {
			Object.keys(checkedItems).map((fileName) => {
				if (checkedItems[fileName]) {
					try {
						let prefix = activeFolder;
						let url = props.apiCxt.endpoint + prefix + "?PK=" + props.contentObject['PK'] + "&file_name=" + fileName + "&account=" + props.apiCxt.account + "&api-token="+props.apiCxt.apiToken
			
						fetch(url, {
							method: 'GET',
						})
						.then((response) => response.blob())
						.then((blob) => {	
							// Create blob link to download
							const url = window.URL.createObjectURL(
							new Blob([blob]),
							);
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute(
								'download',
								fileName,
							);
						
							// Append to html link element page
							document.body.appendChild(link);
						
							// Start download
							link.click();
						
							// Clean up and remove the link
							link.parentNode.removeChild(link);
						});				
						
					} catch (error) {
						console.log('There was an error', error);
						alert(error)
					};
				}
				return false;
			})
		}
	};
	

	// ************** Checkbox selection ******************

	const allItemsHandler = (event) => {
		setAllItemsCBox(event.currentTarget.checked);
		let checkMap = checkedItems;

		items.map((item) => {
			checkMap[item['Name']] = event.currentTarget.checked;
			document.getElementById(item['Name']).checked = event.currentTarget.checked;
			return false;
		})
		setCheckedItems(checkMap);
		setCheckedCount(Object.keys(checkMap).reduce(function (count, key) {
			return count += checkMap[key] ? 1 : 0;
		}, 0));
	};

	const itemHandler = (event) => {
		setAllItemsCBox(false);
		let checkMap = checkedItems;

		checkMap[event.currentTarget.id] = event.currentTarget.checked;
		setCheckedItems(checkMap);

		setCheckedCount(Object.keys(checkMap).reduce(function (count, key) {
			return count += checkMap[key] ? 1 : 0;
		}, 0));
	};


	// ************** delete Dialog ******************
  
	const handleFileDelete = () => {
		if (props.apiCxt.apiToken) {
			let deleteNames = Object.keys(checkedItems).filter((key) => {
				return checkedItems[key] ? key : null;
			});

			try {
				let prefix = activeFolder;
				let url = props.apiCxt.endpoint + prefix + "?PK=" + props.contentObject.PK + "&file_names=" + deleteNames.join(',') + "&api-token="+props.apiCxt.apiToken
	
				fetch(
					url, 
					{ method: "DELETE"}
				).then(response => {
					return response.json();
				}).then(data => {
					if ('deleted' in data) {
						loadItemsTable();
 					} 
					if ('exception' in data) {
						let msg = 'exception: ' + data['exception']

						alert(msg);
					}
					setDeleteDlgOpen(false);
				});	
			} catch (error) {
				console.log('There was an error', error);
                alert(error)
			};
		}
	};
	

	// *********************** folder tree ***************

	const loadFolderTree = useCallback(() => {
		setFolderMap({});
		
		if (props.apiCxt.apiToken && props.contentObject && 'files' === props.activeTab) {
			setIsLoading(true);

			
			//document.getElementById('search-input').value = props.contentPK;
			try {
				var url = props.apiCxt.endpoint + "files?PK=" + props.contentObject['PK'] + "&api-token="+props.apiCxt.apiToken
				
				fetch(
					url
				).then(response => {
					return response.json();
				}).then(jsonData => {
					setIsLoading(false);
					if ('files' in jsonData) {
						setFolderMap(parseFolderJSON(jsonData, '', true));
					}		
				});	
			} catch (error) {
				setIsLoading(false);
				console.log('There was an error', error);
			};
		}
	}, [props.contentObject, props.activeTab, props.apiCxt, parseFolderJSON])


	useEffect(()=> {
		setActiveFolder('files');
		setAllItemsCBox(false)
		setCheckedItems([]);
		setCheckedCount(0);
		setDropFiles([]);
		setDropCount(0);

		loadFolderTree();
	}, [props.apiCxt, props.activeTab, parseFolderJSON, loadFolderTree])


	// load items table

	const loadItemsTable = useCallback(() => {
		if (props.apiCxt.apiToken && 'files' === props.activeTab) {
			setItemsLoading(true);
			//document.getElementById('search-input').value = props.contentPK;
			try {
				var url = props.apiCxt.endpoint
				
				url += activeFolder + "?items&attributes&PK=" + props.contentObject['PK'] + "&api-token="+props.apiCxt.apiToken
				fetch(
					url
				).then(response => {
					return response.json();
				}).then(jsonData => {
					setItemsLoading(false);
					if ('items' in jsonData) {
						setItems(jsonData['items'])
					}
				});	
			} catch (error) {
				console.log('There was an error', error);
				setItemsLoading(false);
			};
		}
	}, [props.apiCxt, props.contentObject, props.activeTab, activeFolder])

	useEffect(()=> {
		setItems([]);
		setAllItemsCBox(false)
		setCheckedItems([]);
		setCheckedCount(0);
		loadItemsTable();

	}, [props.apiCxt, loadItemsTable])
	

	return <div>
		<SplitPane split="vertical" defaultSize={200} style={{'height':null, 'top':'0px', 'bottom':'0px'}}>
			<div >
				{isLoading && <div>Loading...</div> }
				{!isLoading &&
					<TreeView
						aria-label="icon expansion"
						defaultCollapseIcon={<ExpandMoreIcon />}
						defaultExpandIcon={<ChevronRightIcon />}
						sx={{ 'position':'absolute','top':'0px', bottom: '0px', flexGrow: 1, width:'100%', overflowY: 'auto' }}
					>
						{ makeTreeItems(folderMap, '') }
						
					</TreeView>
				}
			</div>
			<div style={{'position':'absolute', 'left':'0px', 'right':'8px', 'height':'100%', 'overflow':'auto','marginTop':'8px'}} >
				<Card style={{'margin':'8px'}}>
					<div id="drop-area">
						<form>
							{ (0 === dropCount) && <div style={{'textAlign':'center','color':'#aaa'}}>drop files here</div> }
							{ dropFiles.map((file, i) => {
								return <div key={'file_' + i} >{file.name}</div>
							})}
						</form>
					</div>
					<div>
						<Button type="button"  component="label">Select files...
							<input type="file" id="fileElem" multiple accept="*/*" onChange={handleFileSelect} />
						</Button>
						<Button type="button" disabled={0 === dropCount} onClick={handleFileUploadDlgOpen} >Upload...</Button>
						<Button type="button" disabled={0 === dropCount} color="error" onClick={handleClear}>Clear</Button>
					</div>
				</Card>
				<Card style={{'margin':'8px'}}>
					<TableContainer component={Paper}>
							<Table size="small" aria-label="a dense table">
								<TableHead>
									<TableRow> 
										<TableCell>
											<input id="allItemsCBox" type="checkbox" checked={allItemsCBox} onChange={allItemsHandler} ref={allItemsCBoxRef} />
										</TableCell>
										<TableCell align="left"><b>Name</b></TableCell>
										<TableCell align="right"><b>LastModified</b></TableCell>
										<TableCell align="right"><b>ObjectSize</b></TableCell>
										<TableCell align="right"><b>StorageClass</b></TableCell>
									</TableRow>
								</TableHead>
								<TableBody >
									{itemsLoading && <TableRow><TableCell>Loading...</TableCell></TableRow>}
									{!itemsLoading && items.map((item, i) => {
											return 	<TableRow key={'log_' + i}sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell>
													<input id={item['Name']} type="checkbox" onChange={itemHandler} />
												</TableCell>
												<TableCell align="left">{item['Name']}</TableCell> 
												<TableCell align="right">{item['LastModified']}</TableCell>
												<TableCell align="right">{item['ObjectSize']}</TableCell>
												<TableCell align="right">{item['StorageClass']}</TableCell>
											</TableRow>
									})}
								</TableBody>
							</Table>
						<div>
							<Button type="button" disabled={0 === checkedCount} onClick={handleDownload} >Download</Button>
							<Button type="button" disabled={0 === checkedCount} color="error" onClick={()=>setDeleteDlgOpen(true)} > Delete...</Button>
						</div>
					</TableContainer>
				</Card>
			</div>
		</SplitPane>
		<Dialog open={uploadDlgOpen} onClose={handleFileUploadDlgClose}>
			<DialogTitle>Upload Files</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					margin="dense"
					id="subfolder"
					label="create subfolder (optional)"
					type="text"
					fullWidth
					variant="standard"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleFileUpload}>Upload</Button>
				<Button onClick={handleFileUploadDlgClose}>Cancel</Button>
			</DialogActions>
		</Dialog>
		<Dialog open={deleteDlgOpen} onClose={()=>setDeleteDlgOpen(false)}>
			<DialogTitle>Delete Files</DialogTitle>
			<DialogContent>Are you sure you want to delete {checkedCount} file(s)?</DialogContent>
			<DialogActions>
				<Button color="error" onClick={handleFileDelete}>Delete</Button>
				<Button onClick={()=>setDeleteDlgOpen(false)}>Cancel</Button>
			</DialogActions>
		</Dialog>
	</div>
}

export default S3Uploader;


import S3Uploader from '../components/S3Uploader';

function FilesTab(props) {

	return(
		<div style={{'position':'absolute', 'top':'0px','bottom':'0px', 'width':'100%','marginTop':'8px'}} >
			<S3Uploader page={props.page} contentPK={props.contentPK} activeTab={props.activeTab} contentObject={props.contentObject} apiCxt={props.apiCxt} />
		</div>
	);
}

export default FilesTab;

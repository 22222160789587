
import { useCallback } from 'react';

import css from './V3Object.module.css';

function V3Object(props) {

	const clickHandler = useCallback((event) => {
		event.preventDefault();
		props.setListPK(props.object.PK);
		props.setContentPK(props.object.PK)
		//props.setRefreshCallback(props.refreshList);
		return true;
	}, [props]);

	function linkHandler(event) {
		event.preventDefault();
		return true;
	}

	if (props.listPK === props.object.PK) {
		return (
			<li>
				<div className={`${css.resourceItem} ${css.resourceSelected}`} style={props.contentPK === props.object.PK ? {'border':'2px solid black'} : {}} onClick={clickHandler}>
					<span className={css.resourceTitle}>{props.object.name}</span> 
					<table style={{'width':'100%'}}>
						<tbody>
							<tr>
								<td><a href="#" onClick={linkHandler} style={{'color':'#428bca'}}>{props.object[props.class_id]}</a>&nbsp;&nbsp;</td> 
								<td style={{'textAlign':'center'}}></td> 
								<td className="pull-right" style={{'textAlign':'right'}}></td>
							</tr>
						</tbody>
					</table>
				</div>
			</li>
		)
	}
	
	return(
		<li>
			<div className={`${css.resourceItem}`} onClick={clickHandler}>
				<span className={css.resourceTitle}>{props.object.name}</span> 
				<table style={{'width':'100%'}}>
					<tbody>
						<tr>
							<td><span>{props.object[props.class_id]}&nbsp;&nbsp;</span></td> 
							<td style={{'textAlign':'center'}}></td> 
							<td className="pull-right" style={{'textAlign':'right'}}></td>
						</tr>
					</tbody>
				</table>
			</div>
		</li>
	);
}

export default V3Object;


import { useState, useRef, useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state'

//import $ from 'jquery';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import css from './V3Object.module.css';


function V3ListHeader(props) {
	const [listHeader, setListHeader] = useLocalStorageState(props.page + '_' + props.objectClass + '_listHeader', { defaultValue: {'IDQuery':'', 'tagQuery':'', 'groupTags':false, 'rootModal': false, 'tagGroups':{},'untagged':[]} })
	const [parentPK] = useLocalStorageState(props.page + '_' + props.parentClass + '_listPK', { defaultValue: "" })
	const [, setListPK] = useLocalStorageState(props.page + '_' + props.objectClass + '_listPK', { defaultValue: "" })
	const [, setContentPK] = useLocalStorageState(props.page + '_contentPK', { defaultValue: "" });
	const IDQueryRef = useRef();
	const tagQueryRef = useRef();
	const groupTagsRef = useRef();
	const rootModelRef = useRef();
	const newIDRef = useRef();
	const [newIDValue, setNewIDValue] = useState("")
	const newNameRef = useRef();
	const [open, setOpen] = useState(false);
	const gridIDRef = useRef();

	//open Create dialog
	const handleClickOpen = () => {
		setOpen(true);
	};
  
	//close Create dialog
	const handleClose = () => {
		setOpen(false);
	};
  
	//handle Create ID text field
	const newIDHandler = (event) => {
		//if (!event.currentTarget.value || /^\d+$/.test(event.currentTarget.value)) {	//allow empty string or integers
		if (!event.currentTarget.value || /^[_a-zA-Z0-9]+$/.test(event.currentTarget.value)) {	//allow integers or commas
			setNewIDValue(event.currentTarget.value)
		}
		return false;
	}

	const handleCreate = () => {
		setOpen(false);

		if (props.apiCxt.apiToken) {
			try {
                var url = props.apiCxt.endpoint + props.objectClass + '?api-token=' + props.apiCxt.apiToken

				if (newIDValue) {
					url += '&new_id=' + newIDValue
				}
				if (newNameRef.current) {
					let newName = document.getElementById(props.page + '_' + props.objectClass + '_newName').value;

					url += '&name=' + newName
				}
				if (props.apiCxt.platform) {
					url += '&platform_id=' + props.apiCxt.platform
				}
				if ('posteriors' === props.objectClass) {
					let gridID = document.getElementById(props.page + '_' + props.objectClass + '_grid_id').value;
					
					url += '&grid_id=' + gridID 
				}
                if (props.parentClass && parentPK) {
                    //if (!parentPK) {
					//	alert('invalid parent id');
                    //    return;
                    //}
                    if ("subjects" === props.parentClass) {
                        url += '&subject_key=' + parentPK
                    } else {
                        url += '&parent_key=' + parentPK
                    }
                }
				if (props.apiCxt.account) {
                    url += '&account=' + props.apiCxt.account
                }

				fetch(
					url, 
					{method: "POST"}
				).then(response => {
					return response.json();
				}).then(data => {
					if ('exception' in data) {
						let msg = 'exception: ' + data['exception']
						
						alert(msg);
					} else {
						let PK = ''
						if (props.objectClass in data) {
							if (1 < data[props.objectClass].length) {
								PK = data[props.objectClass][0]['PK']
							}
						}
						setListPK(PK)
						setContentPK(PK)
						props.refreshList()
					}
				});	
			} catch (error) {
				console.log('There was an error', error);
                alert(error)
			};
		}
	};
	
	function IDQueryHandler(event) {
		//setIDQuery(event.currentTarget.value)
		if(event.key === 'Enter') {
			listHeader.IDQuery = event.currentTarget.value;
			setListHeader(listHeader);

			//close the dropdown
			let dd = document.getElementById(props.page + '_' + props.objectClass + '_dropdown');
			dd.click(event);
		}
		return true;
	}

	function tagQueryHandler(event) {
		if(event.key === 'Enter') {
			//setTagQuery(event.currentTarget.value)
			listHeader.tagQuery = event.currentTarget.value;
			setListHeader(listHeader);

			//close the dropdown
			let dd = document.getElementById(props.page + '_' + props.objectClass + '_dropdown');
			dd.click(event);
		}
		return true;
	}

	function groupTagsHandler(event) {
		//setGroupTags(event.currentTarget.checked);
		listHeader.groupTags = event.currentTarget.checked;
		setListHeader(listHeader);

		//close the dropdown
		let dd = document.getElementById(props.page + '_' + props.objectClass + '_dropdown');
		dd.click(event);
		
		return true;
	}

	function rootModelHandler(event) {
		//setRootModel(event.currentTarget.checked);
		listHeader.rootModel = event.currentTarget.checked;
		setListHeader(listHeader);
		
		if (event.currentTarget.checked) {
			props.setListPK("grids$" + props.apiCxt.account + ":");
			props.setContentPK("grids$" + props.apiCxt.account + ":");
		} else {
			props.setListPK("");
			props.setContentPK("");
		}
		//close the dropdown
		let dd = document.getElementById(props.page + '_' + props.objectClass + '_dropdown');
		dd.click(event);
		
		return true;
	}

	//make sure inputs are loaded first time through
	useEffect(()=> {
		IDQueryRef.current.value = listHeader.IDQuery;
		tagQueryRef.current.value = listHeader.tagQuery;
	}, [listHeader])


/*
	useEffect(()=> {
        setTags([])
		if (props.apiCxt.apiToken) {
			try {
                var url = props.apiCxt.endpoint + 'tags?object_class=' + props.objectClass + '&notify=none&limit=1000&api-token=' + props.apiCxt.apiToken

                if (props.parentClass) {
                    if (!parentPK) {
                        return;
                    }
                    if ("subjects" === props.parentClass) {
                        url += '&subject_key=' + parentPK
                    } else {
                        url += '&parent_key=' + parentPK
                    }
                }
                if (props.apiCxt.account) {
                    url += '&account=' + props.apiCxt.account
                }  

				fetch(
					url
				).then(response => {
					return response.json();
				}).then(data => {
                    if ('tags' in data) {
                        setTags(Object.keys(data['tags']));	
 					} 
					if ('exception' in data) {
                        let msg = 'exception: ' + data['exception']
                        
						console.log(msg);
                        document.getElementById(props.page + '_' + props.parentClass + '_loading').textContent = msg;
					}
				});	
			} catch (error) {
				setTags([]);
				console.log('There was an error', error);
                document.getElementById(props.page + '_' + props.parentClass + '_loading').textContent = error;
			};
		}
	}, [parentPK, IDQuery, props.page, props.objectClass, props.parentClass, props.apiCxt, setTags])
*/

	return(
		<div className="v3-list-header">
			<button className={"btn btn-link " + css.rhPlusBtn } style={{'padding':'4px'}} onClick={handleClickOpen} ><span className="fa fa-plus"></span></button>
			<ul style={{'textAlign':'center','padding':'2px','borderTop':'1px solid grey','borderBottom':'1px solid grey'}} >
				<li className="nav-item dropdown">
					<button id={props.page + '_' + props.objectClass + '_dropdownBtn'} className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style={{'textAlign':'center','width':'100%'}} >
						{props.objectClass}
					</button>
					<ul id={props.page + '_' + props.objectClass + '_dropdown'} className="dropdown-menu">
						<div className="px-1 py-1">
							<table>
								<tbody>
									<tr>
										<td style={{'fontWeight':'bold'}}>id: </td> 
										<td><input id={props.page + props.objectClass + "_id"} style={{'width':'100%'}} type="text" onKeyDown={IDQueryHandler} ref={IDQueryRef} /></td> 
									</tr>
									<tr>
										<td style={{'fontWeight':'bold'}}>tags: </td> 
										<td><input id={props.page + props.objectClass + "_tags"} style={{'width':'100%'}} type="text" onKeyDown={tagQueryHandler} ref={tagQueryRef} /></td> 
									</tr>
									<tr>
										<td style={{'fontWeight':'bold'}}></td> 
										<td>
											<input id={props.page + props.objectClass + "_groupTags"} type="checkbox" checked={listHeader.groupTags} onChange={groupTagsHandler} ref={groupTagsRef} />
											<label htmlFor="groupTags">&nbsp;Group By Tags</label>
										</td> 
									</tr>
									{ 'models' === props.objectClass &&
										<tr>
											<td style={{'fontWeight':'bold'}}></td> 
											<td>
												<input id={props.page + props.objectClass + "_rootModel"} type="checkbox" checked={listHeader.rootModel} onChange={rootModelHandler} ref={rootModelRef} />
												<label htmlFor="rootModel">&nbsp;All Grids</label>
											</td> 
										</tr>
									}
								</tbody>
							</table>
							{ 
								//tags.map((tag) => {
								//	return <button key={props.page + '_' + props.parentClass + tag} onClick={tagHandler} className="dropdown-item" >{tag}</button>
								//})
							}
						</div>
					</ul>
				</li>
			</ul>
			{listHeader.tagQuery && <div></div>}
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Create {props.objectClass.substring(0,props.objectClass.length-1)}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						If no ID is specified a random one will be genarated.
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id={props.page + '_' + props.objectClass + '_newID'}
						label="new object ID"
						type="text"
						fullWidth
						variant="standard"
						ref={newIDRef}
						value={newIDValue}
						onChange={newIDHandler} 
					/>
					<TextField
						autoFocus
						margin="dense"
						id={props.page + '_' + props.objectClass + '_newName'}
						label="object name"
						type="text"
						fullWidth
						variant="standard"
						ref={newNameRef}
					/>
					{ 'posteriors' === props.objectClass && <TextField
						autoFocus
						margin="dense"
						id={props.page + '_' + props.objectClass + '_grid_id'}
						label="grid_id"
						type="text"
						fullWidth
						variant="standard"
						ref={gridIDRef}
					/>

					}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCreate}>Create</Button>
					<Button onClick={handleClose}>Cancel</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default V3ListHeader;

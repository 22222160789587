
import { useState, useEffect } from 'react';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

function ResultsTab(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [htmlData, setHtmlData] = useState([]);
	
	useEffect(()=> {
		setIsLoading(true);
		//setHtmlData("");

 		if (props.apiCxt.apiToken && props.contentPK && ('results' === props.activeTab || 'marginals' === props.activeTab)) {
			//document.getElementById('search-input').value = props.contentPK;
			try {
				let propVal = 'marginals' === props.activeTab ? "axis_marginals" : '';

				var url = props.apiCxt.endpoint + props.contentPK.replace("$", "/") + "?plot=" + propVal + "&tick_count=10&api-token="+props.apiCxt.apiToken
				console.log(props.activeTab)
				fetch(
					url
				).then(response => {
					return response.text();
				}).then(htmlData => {
					setIsLoading(false);
					setHtmlData(htmlData);			
				});	
			} catch (error) {
				console.log('There was an error', error);
				setIsLoading(false);
				setHtmlData("");		
			};
		}
	}, [props.contentPK, props.objectClass, props.apiCxt, props.activeTab])

	if (isLoading) {
		return(
			<section><p>Loading... </p></section>
		);
	};

	if (htmlData) {
		return(
			<Container component={Paper} style={{'width':'100%'}}>
				<iframe style={{'position':'absolute','width':'100%','height':'100%','bottom':'-30'}} title="plot" srcDoc={htmlData} />    
			</Container>
		);
	};
}

export default ResultsTab;

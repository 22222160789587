
import { useEffect, useState } from 'react';

import useLocalStorageState from 'use-local-storage-state'

import SplitPane from 'react-split-pane';
import V3ObjectList from "../components/V3Object/V3ObjectList";
import ContentTabs from '../components/ContentTabs';

function SubjectsPage(props) {
	const [contentPK, setContentPK] = useLocalStorageState('subjectsPage_contentPK', { defaultValue: "" })
	const [refreshCallback, setRefreshCallback] = useState(null)
	
/* ***TBI?	
	useEffect(()=> {
		console.log('subjectsPage refreshCallback ' + refreshCallback)
	}, [refreshCallback])
*/

	useEffect(()=> {
		// clear our contentPK if we're no longer in the same account
		if (contentPK) {
			const classSplits = contentPK.split('$')
			const accountSplits = classSplits[1].split(':')

			if (accountSplits[0] !== props.apiCxt.account) {
				setContentPK('');
				//console.log(accountSplits[0] + ' -- ' + props.apiCxt.account)
			}
		}
		
	}, [contentPK, setContentPK, props.apiCxt])

	return <div>
		<span className="fa fa-chevron-right tabControl"></span>
		<SplitPane split="vertical" defaultSize={200} style={{'height':null, 'top':'63px', 'bottom':'0px'}}>
			<SplitPane split="horizontal" defaultSize={300} paneStyle={{'overflow':'auto'}}>
				<div style={{width:'100%'}}>
					<V3ObjectList contentPK={contentPK} setContentPK={setContentPK} setRefreshCallback={setRefreshCallback} page='subjectsPage' objectClass='subjects' class_id='subject_id' parentClass={ props.apiCxt.platform ? "platforms" : ''} apiCxt={props.apiCxt} />
				</div>
				<div>
					<div style={{width:'100%'}}>
						<V3ObjectList contentPK={contentPK} setContentPK={setContentPK} setRefreshCallback={setRefreshCallback} page='subjectsPage' objectClass='records' class_id='record_id' parentClass='subjects' apiCxt={props.apiCxt} />
					</div>	
				</div>
			</SplitPane>
			<SplitPane split="vertical" defaultSize={200}>
				<SplitPane split="horizontal" defaultSize={300} >
					<div style={{width:'100%'}}>
						<V3ObjectList contentPK={contentPK} setContentPK={setContentPK} setRefreshCallback={setRefreshCallback} page='subjectsPage' objectClass='posteriors' class_id='posterior_id' parentClass='records' apiCxt={props.apiCxt} />
					</div>
					<div style={{width:'100%'}}>
						<V3ObjectList contentPK={contentPK} setContentPK={setContentPK} setRefreshCallback={setRefreshCallback} page='subjectsPage' objectClass='analytics' class_id='analytic_id' parentClass='posteriors' apiCxt={props.apiCxt} />
					</div>
				</SplitPane>
				<div >
					<ContentTabs contentPK={contentPK} refreshCallback={refreshCallback} page='subjectsPage' apiCxt={props.apiCxt} ></ContentTabs>
				</div>
			</SplitPane>	
		</SplitPane>
	</div>
}

export default SubjectsPage;

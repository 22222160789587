
import { useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function LogsTab(props) {
	const [isLoading, setIsLoading] = useState(true);
	const [logs, setLogs] = useState([]);
	
	useEffect(()=> {
		setIsLoading(true);
		setLogs([]);

 		if (props.apiCxt.apiToken && props.contentPK && 'logs' === props.activeTab) {
			//document.getElementById('search-input').value = props.contentPK;
			try {
				var url = props.apiCxt.endpoint + "logs?PK=" + props.contentPK + "&api-token="+props.apiCxt.apiToken
				
				fetch(
					url
				).then(response => {
					return response.json();
				}).then(jsonData => {
					setIsLoading(false);
					if ('logs' in jsonData) {
						setLogs(jsonData['logs']);	
					}		
				});	
			} catch (error) {
				console.log('There was an error', error);
			};
		}
	}, [props.contentPK, props.objectClass, props.apiCxt, props.activeTab])

	if (isLoading) {
		return(
			<section><p>Loading... </p></section>
		);
	};

	if (logs) {
		return(
			<div style={{'position':'absolute', 'top':'0px','bottom':'0px', 'width':'100%','overflow':'auto','marginTop':'8px'}} >
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell align="left">Date</TableCell>
								<TableCell align="right">Type</TableCell>
								<TableCell align="right">Context</TableCell>
								<TableCell align="left">Message</TableCell>
							</TableRow>
						</TableHead>
						<TableBody >
							{logs.map((log, i) => {
									return 	<TableRow key={'log_' + i}sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
										<TableCell align="left">{log['SK']}</TableCell> 
										<TableCell align="right">{'type' in log ? log['type'] : ''}</TableCell>
										<TableCell align="right">{log['cxt']}</TableCell>
										<TableCell align="left">{log['msg']}</TableCell>
									</TableRow>
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		);
	};
}

export default LogsTab;


import { useState, useRef } from 'react';
import useLocalStorageState from 'use-local-storage-state'

import css from './LoginPage.module.css'

function LoginPage(props) {
	const emailRef = useRef();
	const apiTokenRef = useRef();
	const [, setLoggedIn] = useLocalStorageState('loggedIn', { defaultValue: false })
	const [apiCxt, setApiCxt] = useLocalStorageState('apiCxt', { defaultValue: {API:'embody-prod', 'endpoint':'', 'apiToken':'', 'account':'', 'admin':false} })
	const [loginError, setLoginError] = useState(false);

	function loginHandler(event) {
		event.preventDefault();

		const	email = emailRef.current.value;
		const	tokenStr = apiTokenRef.current.value;

		try {
			fetch(
				apiCxt.endpoint + 'api-tokens?email=' + email + '&api-token=' + tokenStr
			).then(response => {
				if (response.ok) {
					return response.json();
				}
				return null;
			}).then(data => {
				if (data && 'api-tokens' in data && 1 === data['api-tokens'].length) {
					let user = data['api-tokens'][0];

					if (user['email'] === email) {
						//setApiToken(tokenStr);
						//setAccount(user['account']);
						apiCxt.apiToken = tokenStr;
						apiCxt.userAccount = user['account'];		//user's home account
						apiCxt.account = user['account'];			//account currently selected in UI

						if (user['status'] instanceof Object) {
							apiCxt.admin = 'admin' in user['status']
							apiCxt.dev = 'dev' in user['status']
						} else {
							apiCxt.admin = 'admin' === user['status']	//true if the user is an admin
							apiCxt.dev = false;
						}
						setApiCxt(apiCxt);
						setLoggedIn(true);
						setLoginError(false);
					}
				} else {
					setLoginError(true);
				}
			});	
		} catch (error) {
			setLoginError(true);
			console.log('There was an error', error);
		};
	};

	return(
		<div className={css.bg} >
			<div className="container centered-div" >
				<div className="row hidden-xs hidden-sm"><br/><br/><br/></div>
				<div className="row">
					<div className="col-md-7"></div>
					<div className="col-md-5" style={{'marginTop':'226px'}}>
						<div>
							<div className="panel-body">
								<div className="row">
									<div className="col-sm-10">
										<form onSubmit={loginHandler}>
											<div className="modal-body">
													<div className="mb-3">
														<input type="email" className="form-control" ref={emailRef} placeholder="email" />
													</div>
													<div className="mb-3">
														<input type="password" className="form-control" ref={apiTokenRef} placeholder="password" />
													</div>
											</div>
											<button className="btn btn-primary" >Log In</button>
										</form>
									</div>
								</div>
							</div>
						</div>
						{ loginError ? <p style={{'color':'red'}}>Login Error</p> : null}
					</div>
				</div>	
			</div>	
		</div>	
	);
}

export default LoginPage;

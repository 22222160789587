
//import { useState } from 'react';

function AdminPage() {
	return <div>
		<h1 style={{textAlign:'center'}}>Admin Page</h1>
		<div className="container">
			 <div className="row">
				<div className="col-xs-10 col-xs-offset-1">
				</div>
			</div>
		</div> 
	</div>
}

export default AdminPage;


import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'

import useLocalStorageState from 'use-local-storage-state'

import css from '../App.module.css'

function Header(props) {
	var [activeNavID, setActiveNavID] = useLocalStorageState('activeNavID', { defaultValue: null })
	const [platforms, setPlatforms] = useLocalStorageState('platforms', { defaultValue: [] })
	const [, setModelParentPK] = useLocalStorageState('gridsPage_models_listPK', { defaultValue: "" })	// used to set platform paren
	//const navigate = useNavigate();
	const searchRef = useRef();


	// select page in header
	const handleClick = (event) => {		
		if (activeNavID) {
			const activeNavItem = document.getElementById(activeNavID)

			if (activeNavItem) {
				activeNavItem.style.backgroundColor = null;
			}
		}
		event.currentTarget.style.backgroundColor = 'lightGray';
		setActiveNavID(event.currentTarget.id)
	};


	const selectPlatform = (event) => {
		if (activeNavID && activeNavID !== 'platform-nav') {
			const activeNavItem = document.getElementById(activeNavID)

			if (activeNavItem) {
				activeNavItem.style.backgroundColor = null;
			}
		}
			
		props.apiCxt['platform'] = event.currentTarget.id;
		props.setApiCxt(props.apiCxt)
		setModelParentPK("platforms$" + props.apiCxt.account + ":" + event.currentTarget.id)
		//setActiveNavID('platform-nav');
		//navigate("/platform");
	}


	const selectAccount = (event) => {		
		props.apiCxt['account'] = event.currentTarget.id;
		props.apiCxt['platform'] = ''
		props.setApiCxt(props.apiCxt)
		setModelParentPK('')
	};


	const selectEndpoint = (event) => {		
		let API = event.currentTarget.id;

		props.apiCxt['API'] = API;
		props.apiCxt['endpoint'] = props.APIs[API].endpoint;
		props.apiCxt['account'] = props.APIs[API].accounts[0];
		props.apiCxt['platform'] = '';
		props.setApiCxt(props.apiCxt)
	};


	function searchHandler(event) {
		if(event.key === 'Enter') {
			event.preventDefault();
			console.log(event.currentTarget.value);
/*			
			let selObjectSplits = splitKey(event.currentTarget.value);
			let parentKeys = {
				"parentKeys": [
					"platforms$ntt:Otto",
					"subjects$ntt:FullPopulation",
					"posteriors$ntt:9818883169_97802"
				]
			}

			props.apiCxt.account = selObjectSplits[1];

			let px = 0;
			let objectSplits = splitKey(parentKeys[px]);
			let page = '';

			if ('platforms' == objectSplits[0]) {
				props.apiCxt.platform = objectSplits[2]
				++px;
				objectSplits = splitKey(parentKeys[px]);
			}
			if ('subjects' ==  objectSplits[0]) {
				const [, setContentPK] = useLocalStorageState('subjectsPage_contentPK', { defaultValue: "" })

				setContentPK(parentKeys[px])
				page = 'subjectsPage';
			} else if ('models' ==  objectSplits[0]) {
				const [, setContentPK] = useLocalStorageState('gridsPage_contentPK', { defaultValue: "" })

				setContentPK(parentKeys[px])
				page = 'gridsPage';
			}
			
			for (; px < parentKeys.length; ++px) {
				let objectSplits = splitKey(parentKeys[px]);
				let parentSplits = splitKey(parentKeys[px-1])

				const [, setListPK] = useLocalStorageState(page + '_' + objectSplits[0] + '_listPK', { defaultValue: "" })
				const [, setParentPK] = useLocalStorageState(page + '_' + parentSplits[0] + '_listPK', { defaultValue: "" })	
				setListPK(parentKeys[px])	
				setParentPK(parentKeys[px-1])				
			}
*/
		}
		return true;
	}
	
	//set active background on load
	useEffect(() => {
		if (activeNavID) {	
			const activeNavItem = document.getElementById(activeNavID)

			if (activeNavItem) {
				activeNavItem.style.backgroundColor = 'lightGray';
			}
		}
	}, [activeNavID]);

	
	// load platfroms 
	useEffect(()=> {
		setPlatforms([])
 		if (props.apiCxt.apiToken) {
			//document.getElementById('search-input').value = props.contentPK;
			try {
				var url = props.apiCxt.endpoint + "platforms?account="+props.apiCxt.account+"&api-token="+props.apiCxt.apiToken
				
				fetch(
					url
				).then(response => {
					return response.json();
				}).then(jsonData => {
					
					if ('platforms' in jsonData) {
						setPlatforms(jsonData['platforms']);	
					}		
				});	
			} catch (error) {
				console.log('There was an error', error);
			};
		}
	}, [props.apiCxt, setPlatforms])

	return (
		<header >
			<nav className="navbar navbar-expand-lg " style={{'backgroundColor': 'rgb(114, 176, 253)'}}>
				<div className="container-fluid" >
					<span className="navbar-brand mt-2 mt-lg-0" href="#">
						<img
							src="images/Embody_Logo.png"
							height="36"
							alt="Embody Logo" 
							loading="lazy" 
						/>
					</span >				
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav me-auto mb-2 mb-lg-0">
							{ 0 < platforms.length && 
								<li className="nav-item">
									<Link to="/platform" id="platform-nav" className="nav-link " style={{'paddingRight':'0'}} onClick={handleClick} >Platform</Link></li>
							}
							{ 0 < platforms.length && 
								<li className="nav-item dropdown" style={{'backgroundColor':activeNavID==='platform-nav' ? 'lightGray': null}} >
									<button id="platform-nav" className="nav-link dropdown-toggle" style={{'paddingLeft':'0'}} href="#" data-bs-toggle="dropdown" aria-expanded="false">:{props.apiCxt.platform}</button >
									<ul className="dropdown-menu">
										<li key={'none'} ><button id={''} className={'dropdown-item ' + (props.apiCxt.platform===null ? css.dropdownItemChecked : '')} href="#" onClick={selectPlatform}>&lt;none&gt;</button ></li>
										{ 
											platforms.map((platform) => {
												//return <button key={props.page + '_' + props.parentClass + tag} onClick={tagHandler} className="dropdown-item" >{tag}</button>
												return <li key={platform.platform_id} ><button id={platform.platform_id} className={'dropdown-item ' + (props.apiCxt.platform===platform.platform_id ? css.dropdownItemChecked : '')} href="#" onClick={selectPlatform}>{platform.platform_id}</button ></li>
											})
										}
									</ul>
								</li>
							}
							<li className="nav-item" style={{'backgroundColor':activeNavID==='grids-nav' ? 'lightGray': null}} >
								<Link to="/grids" id="grids-nav" className="nav-link " onClick={handleClick} >Models</Link></li>
							<li className="nav-item" style={{'backgroundColor':activeNavID==='subjects-nav' ? 'lightGray': null}} >
								<Link to="/subjects" id="subjects-nav" className="nav-link "  onClick={handleClick} >Subjects</Link></li>
							<li className="nav-item" style={{'backgroundColor':activeNavID==='translators-nav' ? 'lightGray': null}} >
								<Link to="/translators" id="translators-nav" className="nav-link "  onClick={handleClick} >Translators</Link></li>
						</ul>
						<ul className="navbar-nav">
							<li className="nav-item dropdown">
								<button className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
									Account: {props.apiCxt.account}
								</button >
								<ul className="dropdown-menu">
									{ 
										props.APIs[props.apiCxt.API].accounts.map((account) => {
											return (props.apiCxt.admin || account === props.apiCxt.userAccount) && 
												<li key={'li_' + account}><button id={account} className={'dropdown-item ' + (props.apiCxt.account===account ? css.dropdownItemChecked : '')} onClick={selectAccount}>{account}</button ></li>
										})
									}

									{ (props.apiCxt.admin) && <li><hr className="dropdown-divider"/></li> }

									{ (props.apiCxt.admin) &&
										<li><button id="embody-prod" className={'dropdown-item ' + (props.apiCxt.endpoint===props.APIs['embody-prod'].endpoint ? css.dropdownItemChecked : '')} onClick={selectEndpoint}>embody-prod</button ></li>
									}
									{ (props.apiCxt.dev) &&
										<li><button id="embody-dev" className={'dropdown-item ' + (props.apiCxt.endpoint===props.APIs['embody-dev'].endpoint ? css.dropdownItemChecked : '')} onClick={selectEndpoint}>embody-dev</button ></li>
									}
									{ (props.apiCxt.admin) &&
										<li><button id="fulcrum" className={'dropdown-item ' + (props.apiCxt.endpoint===props.APIs['fulcrum'].endpoint ? css.dropdownItemChecked : '')} onClick={selectEndpoint}>fulcrum</button ></li>
									}
									{ (props.apiCxt.dev) &&
										<li><button id="local" className={'dropdown-item ' + (props.apiCxt.endpoint===props.APIs['local'].endpoint ? css.dropdownItemChecked : '')} onClick={selectEndpoint}>local</button ></li>
									}
								</ul>
							</li>							
						</ul>
						<form className="d-flex" role="search">
							<input id='search-input' className="form-control me-2" type="text" placeholder="search" aria-label="search" onKeyDown={searchHandler} ref={searchRef}/>
						</form>
						<button type="button" className="btn btn-outline-primary" onClick={props.logoutHandler}>LogOut</button>
					</div>
				</div>
			</nav>
		</header>
	);
}

export default Header;


import React, { useState, useEffect, useRef, useCallback } from 'react';

import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

import RequestDialog from '../UI/RequestDialog';

function PropertyEditor(props) {
	//const	[isLoading, setIsLoading] = useState(true);
	const	[lastPK, setLastPK] = useState('');
	const	[propMap, setPropMap] = useState({});
	const	[breadCrumbs, setBreadCrumbs] = useState([]);	//used for navigating drilled-down json objects
	const	protectedAttr = ['PK','SK','date','modified_date','parent_key','class_key','user_key','modified_user_key','subject_key'];
	const	inputRefs = useRef({});
	const	newKeyRef = useRef({})
	const	newValueRef = useRef({})

	const	[reqDlgOpen, setReqDlgOpen] = useState(false);
	const	[reqDlgProps, setReqDlgProps] = useState({method:'', url:'', data:null, object:{}});
	
	function isProtected(key) {
		if (0 < breadCrumbs.length) return false;	//only protect top level attributes
		return protectedAttr.includes(key) || key.startsWith('s3_key') || key.endsWith('_key') || key.endsWith('PK') || key.endsWith('_count') || key.endsWith('_time');
	}

	const propChangeHandler = (event) => {
		if (event.currentTarget.id.startsWith('input-')) {
			let propKey = event.currentTarget.id.slice(6)	//pop off 'input-'

			updateContentProp(propKey, event.currentTarget.value, false)
		}
	}
	
	const keyHandler = (event) => {
		if (event.key === 'Enter') {
			console.log('enter')
			if (event.currentTarget.id.startsWith('new')) {
				let newNameInput = document.getElementById('new_key');
				let newValueInput = document.getElementById('new_value');

				if (newNameInput.value) {
					if (!isProtected(newNameInput.value)) {
						updateContentProp(newNameInput.value, newValueInput.value, true);
					}
					newNameInput.value = '';
					newValueInput.value = '';
				}
			} else {
				if (!event.currentTarget.value) {
					let propKey = event.currentTarget.id.slice(6)	//pop off 'input-'
				
					updateContentProp(propKey, null, true)
				}
			}
		}
	}

	const newKeyHandler = (event) => {
		if (event.key === 'Enter') {

			if (newKeyRef.current.value) {
				if (!isProtected(newKeyRef.current.value)) {
					updateContentProp(newKeyRef.current.value, newValueRef.current.value, true);
				}
				newKeyRef.current.value = '';
				newValueRef.current.value = '';
			}
		}
	}
	
	// called when clicking on JSON object link
	const drilldownHandler = (event) => {
		let propKey = event.currentTarget.id.slice(3)	//pop off 'dd-'

		if (0 === breadCrumbs.length) {
			breadCrumbs.push('bcRoot' in props ? props['bcRoot'] : 'properties');
		}
		breadCrumbs.push(propKey);
		updatePropMap();
	}

	// called when clicking on breadCrumb list
	const breadCrumbHandler = (event) => {
		let	propKey = event.currentTarget.id.slice(3)	//pop off 'dd-'
		let	jsonProp = props.contentObject;
		let bc = [];
		const	bcRoot = 'bcRoot' in props ? props['bcRoot'] : 'properties'

		if (bcRoot !== propKey) {
			for (let ix = 0; ix <= breadCrumbs.indexOf(propKey); ++ix) {
				let	prop = breadCrumbs[ix];

				if (bcRoot !== prop) {
					jsonProp = jsonProp[prop]
				}
				bc.push(prop)
			}
		}
		setBreadCrumbs(bc);
		updatePropMap()
	}

	// update display properties using breadcrumb context
	const updatePropMap = useCallback(() => {
		let pm = {}
		let jsonProp = props.contentObject;

		for (let bx=1; bx < breadCrumbs.length; ++bx) {
			const bcKey = breadCrumbs[bx];
			
			if (bcKey in jsonProp) {
				jsonProp = jsonProp[bcKey];
			}
		}
		Object.keys(jsonProp).map((key) => {
			pm[key] = jsonProp[key];
			return null;
		});
		setPropMap(pm);
	}, [breadCrumbs, props.contentObject])


	// update props.contentObject property value using breadcrumb context
	const updateContentProp =  useCallback((propKey, value, deleteIfEmpty) => {
		let	co = props.contentObject;
		let	jsonProp = co;

		for (let bx=1; bx < breadCrumbs.length; ++bx) {
			const bcKey = breadCrumbs[bx];
			
			jsonProp = jsonProp[bcKey];
		}
		if (value) {
			if (value.startsWith('{') || value.startsWith('[')) {
				try {
					value = JSON.parse(value)
				} catch (error) {
					alert(error)
				};
			}
			
			jsonProp[propKey] = value;
		} else {
			if (deleteIfEmpty) {
				delete jsonProp[propKey]; 
			}
		}
		//props.setContentObject(co);
		updatePropMap();
	}, [breadCrumbs, props.contentObject, updatePropMap])

/*
	useEffect(()=> {
		props.setContentObject(null);
		setBreadCrumbs([]);

 		if (props.apiCxt.apiToken && props.contentPK) {
			//check for empty ID (grid root)
			var accountSplits = props.contentPK.split(':')

			if (1 === accountSplits.length || !accountSplits[1]) {
				props.setContentObject(null);	
				return;
			}
			setIsLoading(true);
			//document.getElementById('search-input').value = props.contentPK;
			try {
				var classSplits = props.contentPK.split('$')
				var objectClass = classSplits[0]
				var url = props.apiCxt.endpoint + objectClass + '/' + classSplits[1] + '?account=' + props.apiCxt.account + '&api-token=' + props.apiCxt.apiToken
				
				fetch(
					url
				).then(response => {
					return response.json();
				}).then(data => {
					if (objectClass in data) {
						let rsltObj = data[objectClass][0];

						setIsLoading(false);
						props.setContentObject(rsltObj);
					} else if ('exception' in data) {
                        let msg = 'exception: ' + data['exception']
                        
						console.log(msg);
						let loadingElement = document.getElementById(props.contentPK + '_loading');

						if (loadingElement) {
							loadingElement.textContent = msg;
						}
					}	
				});	
			} catch (error) {
				console.log('There was an error', error);
				let loadingElement = document.getElementById(props.contentPK + '_loading');

				if (loadingElement) {
					loadingElement.textContent = error;
				}
			};
		}
	}, [props.setContentObject, props.contentPK, props.apiCxt])
*/	

	const handleSaveButton = () => {
		let classSplits = props.contentPK.split('$')
		let objectClass = classSplits[0]
		
		reqDlgProps.method = 'PUT';	//use PUT to update an existing object
		reqDlgProps.url = props.apiCxt.endpoint + objectClass + '/' + props.contentPK + '?notify=none&api-token=' + props.apiCxt.apiToken
		reqDlgProps.data = JSON.stringify(props.contentObject);
		reqDlgProps.object = props.contentObject;
		setReqDlgProps(reqDlgProps);
		setReqDlgOpen(true);
	};


	const handleDeleteButton = () => {
		let classSplits = props.contentPK.split('$')
		let objectClass = classSplits[0]
		
		reqDlgProps.method = 'DELETE';	//use PUT to update an existing object
		reqDlgProps.url = props.apiCxt.endpoint + objectClass + '/' + props.contentPK + '?notify=none&api-token=' + props.apiCxt.apiToken
		reqDlgProps.data = JSON.stringify(props.contentObject);
		reqDlgProps.object = props.contentObject;
		reqDlgProps.complete = props.refreshCallback;

		setReqDlgProps(reqDlgProps);
		setReqDlgOpen(true);
	};


	//populate form elements after useRef binding
	useEffect(() => {
		const thisPK = (props.contentObject && 'PK' in props.contentObject) ? props.contentObject.PK : '';

		if (thisPK !== lastPK) {
			setBreadCrumbs([]);
			setLastPK(thisPK);
		}		
		if (props.contentObject) {
			//set up property map			
			updatePropMap()
		}
	}, [props.contentObject, updatePropMap, lastPK]);


	if (props.isLoading) {
		return(
			<section><p id={props.contentPK + '_loading'}>Loading... </p></section>
		);
	};

	
	if (props.contentObject) {
		return(
			<Card style={{'margin':'8px'}}>
				<div>{0 === breadCrumbs.length 
					? <br/>
					: breadCrumbs.map((breadCrumb, i) => {
						return  i < breadCrumbs.length-1 ?  <Link id={'bc-' + breadCrumb} key={'bc-' + breadCrumb} href="#" underline="none" onClick={breadCrumbHandler}>{breadCrumb}/</Link> : <span key={'bc-' + breadCrumb}>{breadCrumb}</span>
					})
				}</div>
				<table style={{'margin':'8px','padding':'8px'}}>
					<tbody>						
						{ 0 === breadCrumbs.length && protectedAttr.map((key) => {
								var value = JSON.stringify(props.contentObject[key]);

								return 	(key in props.contentObject) && <tr key={props.contentPK + key}>
									<td style={{'fontWeight':'bold','minWidth':'80px'}}>{key}</td> 
									<td style={{'width':'100%'}}>
										{ value && <span style={{'fontWeight':'200'}}>{ '"' === value[0] ? value.substring(1, value.length - 1) : value}</span>}
									</td>
								</tr>
							})
						}	
						{ Object.keys((0 === breadCrumbs.length ? props.contentObject : propMap)).map((key) => {
								if (protectedAttr.includes(key)) {
									return '';
								} else {
									let	value = JSON.stringify((0 === breadCrumbs.length ? props.contentObject : propMap)[key]);
									let isString = '"' === value[0];
									let numLines = Math.min(6, Math.ceil(value.length/120));

									if (isString) {
										value = value.substring(1, value.length - 1)
									}
									return 	<tr key={props.contentPK + key}>
										{!isString && <td ><Link id={'dd-' + key} href="#" underline="none" onClick={drilldownHandler}>{key}</Link></td> }
										{isString && <td style={{'fontWeight':'bold','minWidth':'80px'}}>{key}</td> }
										{ (isProtected(key)) 
											?
											<td>	
												{ value && <span style={{'fontWeight':'200'}}>{value}</span>}
											</td> 
											:
											<td style={{'width':'100%'}}>
												{ (1 === numLines) && <input id={'input-' + key} ref={el => inputRefs.current[key] = el} defaultValue={value}  style={{'width':'100%'}} type="text" onKeyUp={keyHandler} onChange={propChangeHandler}/> }
												{ (1 < numLines) && <textarea id={'input-' + key} rows={Math.min(6, Math.ceil(value.length/120))} style={{'width':'100%'}} defaultValue={value} onChange={propChangeHandler} /> }
											</td> 
										}
									</tr>
								}
							})
						}	
						<tr><td><br/></td></tr>
						{ 'PK' in props.contentObject && <tr key="new">
								<td style={{'fontWeight':'bold','minWidth':'80px'}}><input ref={newKeyRef}  placeholder="new key" style={{'width':'100%'}} type="text" onKeyUp={newKeyHandler} onChange={propChangeHandler}/></td> 
								<td> <input ref={newValueRef} placeholder="new value"  style={{'width':'100%'}} type="text" onKeyUp={newKeyHandler} onChange={propChangeHandler}/></td> 
							</tr>
						}
					</tbody>
				</table>
				{ 'PK' in props.contentObject && <div style={{'position':'relative','width':'100%','left':'0px','bottom':'0px','backgroundColor': 'white'}}>
						<Button type="button" onClick={handleSaveButton}>Save...</Button>
						<Button style={{'position':'absolute','right':'0'}} type="button" color="error" onClick={handleDeleteButton} >Delete...</Button>
					</div>
				}
				<RequestDialog open={reqDlgOpen} setOpen={setReqDlgOpen} dlgProps={reqDlgProps} refreshCallback={props.refreshCallback} apiCxt={props.apiCxt} />
			</Card>
		);
	};
}

export default PropertyEditor;


import { useState, useRef, useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function RequestDialog(props) {
	const [showReqButton, setShowReqButton] = useState(true);
	const [dlgURLValue, setDlgURLValue] = useState("")
	const dlgURLRef = useRef();
  	
	const handleSendRequest = () => {
		if (props.apiCxt.apiToken) {
			try {
				let args = {method: props.dlgProps.method}

				if (props.dlgProps.data) {
					args['body'] = props.dlgProps.data;
					args['headers'] = {
						"Content-Type": "application/json",
					}
				}
				fetch(
					props.dlgProps.url, 
					args
				).then(response => {
					return response.json();
				}).then(data => {
                    if (props.objectClass in data) {
                        console.log('done')
 					} 
					if ('exception' in data) {
                        let msg = 'exception: ' + data['exception']
                        
						document.getElementById("reqDlg-response").innerHTML = msg;
					} else {
						document.getElementById("reqDlg-response").innerHTML = JSON.stringify(data);
						setShowReqButton(false);
						
						if (props.dlgProps.complete) {
							props.dlgProps.complete(props.dlgProps);
						}
					}
				});	
			} catch (error) {
				console.log('There was an error', error);
                alert(error)
			};
		}
	}

	//handle change to url text edit
	function reqDlgURLHandler(event) {
		//console.log('reqDlgURLHandler')
		//event.preventDefault();
		setDlgURLValue(event.currentTarget.value)
		props.dlgProps.url = event.currentTarget.value;
		return false;
	}

	const handleReqDlgClose = () => {
		setShowReqButton(true)
		props.setOpen(false);
	};

	useEffect(()=> {
		setDlgURLValue(props.dlgProps.url);
	}, [props.dlgProps.url, setDlgURLValue]);

	return(
		<Dialog fullWidth open={props.open} onClose={props.setOpen}>
			<DialogTitle>API Request</DialogTitle>
			<DialogContent>
				<div>{props.dlgProps.method}</div>
				<textarea id="reqDlg-url" rows="4" style={{'width':'100%'}} ref={dlgURLRef} value={dlgURLValue} onChange={reqDlgURLHandler} />
				<DialogContentText id="reqDlg-response">
					Response
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				{ showReqButton && <Button onClick={handleSendRequest}>Send Request</Button>}
				<Button onClick={handleReqDlgClose}>Done</Button>
			</DialogActions>
		</Dialog>
	);
}

export default RequestDialog;

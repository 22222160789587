
import { useEffect, useState, useCallback } from 'react';
import useLocalStorageState from 'use-local-storage-state'

import SplitPane from 'react-split-pane';
import V3ObjectList from '../components/V3Object/V3ObjectList';
import ContentTabs from '../components/ContentTabs';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

import './react-split-pane.css';


function GridsPage(props) {
	const [contentPK, setContentPK] = useLocalStorageState('gridsPage_contentPK', { defaultValue: "" })
	const [splitsOpen, setSplitsOpen] = useState(2);
	const [splitsInfo, setSplitsInfo] = useState({open:true, width1:'',width2:''});  // open/close v3ObjectList splits using left/right chevron

	useEffect(()=> {
		// clear our contentPK if we're no longer in the same account
		if (contentPK) {
			const classSplits = contentPK.split('$')
			const accountSplits = classSplits[1].split(':')

			if (accountSplits[0] !== props.apiCxt.account) {
				setContentPK('');
			}
		}
		
	}, [contentPK, setContentPK, props.apiCxt])

	
	const handleSplitsOpen = useCallback((event) => {
		let col1 = document.getElementById("grid-col-1");
		let split1 = col1.parentElement;
		let col2 = document.getElementById("grid-col-2");
		let split2 = col2.parentElement;

		switch(splitsOpen) {
			case 2:
				splitsInfo.width2 = split2.style.width;
				split2.style.width = "0px";

				setSplitsInfo(splitsInfo);
				setSplitsOpen(1);
				break;
			case 1:
				splitsInfo.width1 = split1.style.width;
				split1.style.width = "0px";

				setSplitsInfo(splitsInfo);
				setSplitsOpen(0);
				break;
			default:
				split1.style.width = splitsInfo.width1; 
				split2.style.width = splitsInfo.width2;

				setSplitsOpen(2);
				break;
		}
/*
		if (splitsOpen) {
			splitsInfo.width1 = split1.style.width;
			splitsInfo.width2 = split2.style.width;
			
			split1.style.width = "0px"; 
			split2.style.width = "0px";
		} else {
			split1.style.width = splitsInfo.width1; 
			split2.style.width = splitsInfo.width2;
		}
		setSplitsOpen(!splitsOpen);
		setSplitsInfo(splitsInfo);
*/
	}, [splitsOpen, setSplitsOpen, splitsInfo, setSplitsInfo]);

	return <div > 
		<SplitPane split="vertical" defaultSize={200} style={{'height':null, 'top':'63px', 'bottom':'0px'}}>
			<div id={"grid-col-1"} >
				<SplitPane split="horizontal" defaultSize={300} paneStyle={{'overflow':'auto'}}>
					<div style={{width:'100%'}}>
						<V3ObjectList contentPK={contentPK} setContentPK={setContentPK} page='gridsPage' objectClass='models' class_id='model_id' parentClass={ props.apiCxt.platform ? "platforms" : ''} apiCxt={props.apiCxt} />
					</div>
					<div>
						<div style={{width:'100%'}}>
							<V3ObjectList contentPK={contentPK} setContentPK={setContentPK} page='gridsPage'  objectClass='grids' class_id='grid_id' parentClass='models' apiCxt={props.apiCxt} />
						</div>	
					</div>
				</SplitPane>
			</div>
			<SplitPane split="vertical" defaultSize={200}>
				<div id={"grid-col-2"}>
					<SplitPane split="horizontal" defaultSize={300} >
						<div style={{width:'100%'}}>
							<V3ObjectList contentPK={contentPK} setContentPK={setContentPK} page='gridsPage' objectClass='posteriors' class_id='posterior_id' parentClass='grids' apiCxt={props.apiCxt} />
						</div>
						<div style={{width:'100%'}}>
							<V3ObjectList contentPK={contentPK} setContentPK={setContentPK} page='gridsPage' objectClass='analytics' class_id='analytic_id' parentClass='posteriors' apiCxt={props.apiCxt} />
						</div>
					</SplitPane>
				</div>
				<div>
					<span style={{'position':'absolute','color':'gray','zIndex':'10'}} onClick={handleSplitsOpen} >{(splitsOpen !== 0) && <ChevronLeft className="splitChevron" />}{!splitsOpen && <ChevronRight className="splitChevron" />}</span>
					<ContentTabs style={{'width':'100%'}} contentPK={contentPK} page='gridsPage' apiCxt={props.apiCxt}></ContentTabs>
				</div>
			</SplitPane>	
		</SplitPane>
	</div>
}

export default GridsPage;

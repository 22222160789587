
import { useState, useEffect, useCallback } from 'react';

import SplitPane from 'react-split-pane';
import PropertyEditor from '../components/PropertyEditor';
import DocObjectList from '../components/V3Object/DocObjectList';
import TagObject from '../components/V3Object/TagObject';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import '../routes/SubjectsPage';


function ReflinksTab(props) {
	//const	[selectedTag, setSelectedTag] = useState('')
	const	[tagExpr, setTagExpr] = useState('')
	const	[tagMap, setTagMap] = useState({'tagNotesMap':{}, 'noteMap':{}, 'docMap':{}})
	const	[selectedReflink, setSelectedReflink] = useState({})

	// completion routines for modal dialogs called in PropertyEditor, DocObjectList
	// dlgProps is {method:'', url:'', data:null, object:{}}
	const refreshCallback = useCallback((dlgProps) => {
		if ('DELETE' === dlgProps.method) {
			if (dlgProps.object === selectedReflink) {
				setSelectedReflink({})
			}
		}
	}, [selectedReflink]);

	const selectTag = useCallback((tagExpr) => {
		setSelectedReflink('')
		setTagExpr(tagExpr)
	}, []);


	useEffect(()=> {
		setSelectedReflink({});

		if (props.tagMap) {
			setTagExpr(props.tagExpr);
			if ('tagMap' in props) {
				setTagMap(props.tagMap);
			}
		} else if (props.apiCxt.platform && props.contentObject && 'tags' in props.contentObject && 'reflinks' === props.activeTab) {
			//convert object tags definition to tagExpr
			const	objTags = props.contentObject.tags;
			let		tagStr = '';
			let		delim = '';
	
			for(let tag of Object.keys(objTags)) {
				tagStr += delim + tag;
				//delim = ',,'	// use ,, for OR 
				delim = ','
				if (objTags[tag]) {
					tagStr += ':' + objTags[tag]
				}
			}
			//setSelectedTag(tagStr);
			setTagExpr(tagStr);
			// find documents and reflinks for selected tag(s)
			try {
				var url = props.apiCxt.endpoint + 'platforms/tagMap?reflinks&notify=none&platform_id=' + props.apiCxt.platform + '&tags=' + tagStr + '&api-token=' + props.apiCxt.apiToken
				
				if (props.apiCxt.account) {
					url += '&account=' + props.apiCxt.account
				}
	
				fetch(
					url, 
					{method: "GET"}
				).then(response => {
					return response.json();
				}).then(data => {
					//setDocuments(Object.values(data['docMap']));
					if ('tagNotesMap' in data) {
						setTagMap(data)
						setTagExpr('')
					} else if ('exception' in data) {
						let msg = 'exception: ' + data['exception']
						
						console.log(msg);
						alert(msg)
					}
				});	
			} catch (error) {
				console.log('There was an error', error);
			};
		} else {
			setTagMap({'tagNotesMap':{}, 'noteMap':{}, 'docMap':{}})
			setTagExpr('')
		}
	}, [props, setTagMap])
	

	return(
		<Container component={Paper} style={{'width':'100%'}}>
			<SplitPane split="vertical" defaultSize={'20%'} style={{'height':null, 'top':'0px', 'bottom':'0px'}}>
				<div style={{'backgroundColor':'white'}}>
					<div style={{'position':'absolute','textAlign':'center','width':'100%','height':'26px','borderBottom':'1px solid gray','backgroundColor':'white'}} >Tags</div>
					<div style={{'position':'absolute','top':'26px','bottom':'0','width':'100%','overflow':'auto','backgroundColor':'white'}}>
						{ Object.keys(tagMap['tagNotesMap']).map((key) => {
								return <TagObject 
										key={key} 
										object={{'name':key,'count':tagMap['tagNotesMap'][key].length}} 
										tagExpr={tagExpr} 
										setTagExpr={selectTag}
									></TagObject>
							})
						}
					</div>
				</div>
				<SplitPane split="vertical" defaultSize={'70%'}>
					<div style={{'height':'100%','overflowY':'auto'}}>
						<PropertyEditor contentPK={selectedReflink.PK} contentObject={selectedReflink} refreshCallback={refreshCallback} page={props.page} apiCxt={props.apiCxt} />
					</div>
					<DocObjectList tagMap={props.tagMap ? props.tagMap : tagMap} tagExpr={props.tagExpr ? props.tagExpr : tagExpr} selectedReflink={selectedReflink} setSelectedReflink={setSelectedReflink} refreshCallback={refreshCallback} page={props.page} apiCxt={props.apiCxt}  />
				</SplitPane>	
			</SplitPane>
		</Container>
	);
}

export default ReflinksTab;

//import logo from './logo.svg';
import './App.css';

import { Route, Routes } from 'react-router-dom'

import useLocalStorageState from 'use-local-storage-state'

import LoginPage from './UI/LoginPage'
import Header from "./components/Header";
import GridsPage from './routes/GridsPage'
import SubjectsPage from './routes/SubjectsPage'
import TranslatorsPage from './routes/TranslatorsPage'
import PlatformPage from './routes/PlatformPage'
import AdminPage from './routes/AdminPage'

/*
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}
*/


function App(props) {
	const APIs = {
		'embody-prod':{
			'endpoint':'https://nqnqmwlpxj.execute-api.us-east-2.amazonaws.com/prod/api/v3/',
			'accounts':['embody','thorne','ntt','v2'],
			'platforms':['BHN','MetSyn']
		},
		'embody-dev':{
			'endpoint':'https://0eazhd4448.execute-api.us-east-2.amazonaws.com/dev/api/v3/',
			'accounts':['dev','test'],
			'platforms':['BHN','MetSyn']
		},
		'fulcrum':{
			'endpoint':'https://m9yrko8ixg.execute-api.us-east-1.amazonaws.com/fulcrum/api/v3/',
			'accounts':['fulcrum'],
			'platforms':['BHN']
		},
		'local':{
			'endpoint':'http://localhost:5000/api/v3/',
			'accounts':['embody','thorne','ntt','v2','dev','test','fulcrum'],
			'platforms':['BHN','MetSyn','Otto']
		}
	}
/*
	const APIs = {
		'embody':{'account':'embody'},
		'thorne':{'account':'thorne'},
		'ntt':{'account':'ntt'},
		'v2':{'account':'v2'},
		'embody-prod':{
			'endpoint':'https://nqnqmwlpxj.execute-api.us-east-2.amazonaws.com/prod/api/v3/',
			'accounts':['embody','thorne','ntt','v2']
		},
		'embody-dev':{
			'endpoint':'https://0eazhd4448.execute-api.us-east-2.amazonaws.com/dev/api/v3/',
			'accounts':['dev','test']
		},
		'fulcrum':{
			'endpoint':'https://m9yrko8ixg.execute-api.us-east-1.amazonaws.com/fulcrum/api/v3/',
			'accounts':['fulcrum']
		},
		'local':{
			'endpoint':'http://localhost:5000/api/v3/',
			'accounts':['embody','thorne','ntt','v2','dev','test','fulcrum']
		}
	}
*/
	//const endpoint = 'https://nqnqmwlpxj.execute-api.us-east-2.amazonaws.com/prod/api/v3/'
	const [loggedIn, setLoggedIn] = useLocalStorageState('loggedIn', { defaultValue: false })
	const [apiCxt, setApiCxt] = useLocalStorageState('apiCxt', { defaultValue: {API:'embody-prod','endpoint':APIs['embody-prod'], 'account':'', 'platform':'', 'apiToken':'', 'admin':false, 'dev':false} })

	const setAPI = (name) => {
		if (!apiCxt) {
			setApiCxt({'API':name, 'endpoint':'APIs[name].endpoint', 'account':APIs[name].accounts[0], 'platform':APIs[name].platforms[0], 'apiToken':'', 'user_name':'', 'admin':false, 'dev':false})
		} else {
			apiCxt['API'] = name;
			apiCxt['endpoint'] = APIs[name].endpoint
			apiCxt['account'] = APIs[name].accounts[0]
			apiCxt['platforms'] = APIs[name].platforms[0]
			setApiCxt(apiCxt);
		}
	};

	function logoutHandler(event) {
		event.preventDefault();
		//setApiToken("");
		apiCxt['apiToken'] = '';
		setApiCxt(apiCxt)
		setLoggedIn(false)
	}

	if (!loggedIn || !apiCxt || !apiCxt.hasOwnProperty('API')) {
		setAPI('embody-prod')
		return (    
			<LoginPage APIs={APIs} apiCxt={apiCxt} setApiCxt={setApiCxt} setAPI={setAPI}  />
		)
	}
/*
	if (!loggedIn || !apiCxt.hasOwnProperty('API')) return (    
		setApi('') 
		<LoginPage APIs={APIs} />
	)
*/

	return (
		<div >
			<Header APIs={APIs} apiCxt={apiCxt} setApiCxt={setApiCxt} logoutHandler={logoutHandler}/>
			<div>
				<Routes>
					<Route path="/grids" element={<GridsPage apiCxt={apiCxt} />} />
					<Route path="/subjects" element={<SubjectsPage apiCxt={apiCxt} />} />
					<Route path="/translators" element={<TranslatorsPage apiCxt={apiCxt} />} />
					<Route path="/platform" element={<PlatformPage apiCxt={apiCxt} setApiCxt={setApiCxt} />} />
					<Route path="/admin" element={<AdminPage apiCxt={apiCxt} />} />
				</Routes>
			</div>
		</div>
	);
}

export default App;
